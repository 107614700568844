@import '../styles/breakpoints.scss';

.cart-drawer {
  &::part(panel) {
    min-width: 100%;

    @media screen and (min-width: 400px ) {
      min-width: 400px;
    }
  }
  &::part(close-button) {
    font-size: var(--font-size-mlarge);
  }
  &::part(title) {
    font-family: var(--default-font-family);
    font-weight: bold;
    font-size: var(--font-size-mlarge);
  }
  .cart-drawer-contents {
    display: flex;
    flex-direction: column;
  }
  .cart-buttons {
    display: flex;
    justify-content: space-evenly;
  }
  .cart-drawer-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: var(--gap-regular) 0 var(--gap-large) 0;

    .plan-card-mini {
      margin: 0 0 var(--gap-small) 0 !important;
    }

    sl-button > img {
      transform: scale(0.8) translate(0, 2px);
      filter: brightness(0) saturate(100%) invert(100%);
    }

    .cart-drawer-item-quantity {
      height: 30px;
      display: flex;
      align-items: center;
      margin: 0 10px;
      font-size: var(--font-size-regular);
      font-family: var(--default-font-family);
    }
  }
}
