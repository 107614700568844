.settings-form {
  .settings-form-content {
    .payment-gateway-dropdown {
    }
    .payment-gateway-button {
      &::part(base) {
        min-width: 140px;
        justify-content: space-between;
      }
      &::part(label) {
        width: 100%;
        text-align: left;
        margin-left: 4px;
      }
    }

    .alias-container {
      display: flex;
      flex-direction: column;

      .alias-input {
        margin-bottom: var(--gap-regular);
      }

      .alias-details {
        display: flex;
        flex-direction: column;
        transition: opacity ease var(--sl-transition-medium);
        opacity: 1;

        &.loading {
          opacity: 0.2;
        }
      }
    }

    .purchase-message-container {
      display: flex;
      flex-direction: column;

      .purchase-message-help-text {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--gap-regular);
      }

      .purchase-message-preview-container {
        display: flex;
        justify-content: space-between;

        >:first-child {
          margin-right: var(--gap-large);
        }

        .preview-title {
          margin-right: var(--gap-small);
          font-family: var(--default-font-family);
          font-weight: var(--sl-font-weight-semibold);
          font-size: var(--font-size-regular);
        }
      }
    }

    .trading-option {
      border-color: var(--sl-color-primary-80);
      box-shadow: 0 0 20px -10px var(--color-button-submit-shadow);
    }
  }
}
