.merchant-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: var(--radius-sm);
  background-color: transparent;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: var(--radius-sm);
  }

  .merchant-checkbox-icon {
    opacity: 0;
  }

  &:hover {
    .merchant-checkbox-icon {
      color: var(--color-font-regular);
      opacity: 0.5;
      transform: scale(0.8) translate(0, 1px);
    }
  }

  &.active {
    span {
      border: none;
      background-color: var(--action-primary);
      color: white;

      .merchant-checkbox-icon {
        opacity: 1;
        filter: brightness(0) saturate(100%) invert(100%);
        transform: scale(0.6) translate(0, 1px);
      }
    }
  }
}
