.pricing-component-list {
  width: 100%;

  table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
    table-layout: fixed;

    td {
      display: flex;
      padding: var(--gap-small) 0;
      border: 0;
      border-top: 2px solid var(--color-border-light);
      line-height: 16px;

      .component-name {
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }
}
