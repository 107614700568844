.form-section-header {
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: var(--gap-small);

  .header-text {
    font-size: var(--font-size-regular);
    color: var(--color-font-dark);
    font-weight: 500;
    font-family: var(--default-font-family);
    margin-right: var(--gap-regular);
    white-space:nowrap;
  }

  .header-rule {
    border-bottom: 2px solid var(--color-blockquote-light);
    width: 100%;
  }
}
