.shuttle button {
  display: flex;
  align-items: center;
  min-width: 200px;
  min-height: 60px;
  margin-right: -4px;
  border: none;
  border-radius: var(--border-radius-small);
  background: var(--action-primary);
  color: white;
  font-weight: bold;
  font-size: var(--font-size-regular);
  font-family: var(--default-font-family);
  text-transform: uppercase;
  transition: filter var(--speed-fast);

  &:hover {
    color: #ccc;
  }
}

.shuttle {
  display: flex;
  justify-content: flex-end;
}
