@import '../styles/breakpoints';

.skeleton {
  flex-shrink: 0;
  width: var(--plan-group-card-size-sm);
  height: var(--plan-group-card-size-sm);
  border-radius: var(--radius-lg);
  background: linear-gradient(
    to right,
    var(--grey-8) 8%,
    var(--grey-9) 18%,
    var(--grey-8) 33%
  );
  background-color: var(--grey-8);
  background-size: 1200px 100%;
  animation: shimmer 2200ms forwards infinite linear;
  scroll-snap-align: center;

  @media screen and (min-width: $screen-md) {
    width: var(--plan-group-card-size-md);
    height: var(--plan-group-card-size-md);
    border-radius: var(--radius-xl);
  }

  @media screen and (min-width: $screen-lg) {
    width: var(--plan-group-card-size-lg);
    height: var(--plan-group-card-size-lg);
  }
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}
