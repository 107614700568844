.plans-list {
  overflow: auto;
  width: 100%;
  font-size: var(--font-size-sm);

  table {
    min-width: 100%;

    tr {
      height: 41px; // 40px for minimum touchable size + 1px for border

      th,
      td {
        vertical-align: middle;
        padding: 0;

        .container {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    thead {
      width: 100%;

      tr {
        width: 100%;

        th {
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          height: 38px;
          font-weight: 500;
        }
      }
    }
  }

  .drag-icon {
    text-align: center;
  }

  .enabled {
    font-size: var(--font-size-md);
  }

  .product {
    min-width: 120px;
  }

  .plan {
    min-width: 220px;
  }

  .price {
    min-width: 120px;
  }

  .controls {
    min-width: 94px;
  }

  .plans-table-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff55;
  }
}
