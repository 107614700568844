@import '../styles/breakpoints';

.merchant-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 var(--gap-xxlarge) var(--gap-xxlarge) var(--gap-xxlarge);

  .merchant-page-title-section {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: var(--gap-large) 0;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      align-items: center;
    }

    .merchant-page-title-container {
      margin-right: var(--gap-regular);

      .merchant-page-title {
        margin-right: var(--gap-regular);
        font-size: var(--font-size-xlarge);
      }

      .merchant-page-subtitle {
        color: var(--color-font-faded);
        font-size: var(--font-size-mlarge);
        font-family: var(--default-font-family);
      }
    }
  }

  .merchant-page-description {
    width: 100%;
    margin-bottom: var(--gap-large);
    font-size: var(--font-size-msmall);
    text-align: left;
  }

  .merchant-page-content {
    width: 100%;
    height: 100%;
  }

  .save-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: var(--gap-regular);
  }
}
