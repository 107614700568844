sl-button {
  &::part(base)  {
    display: flex;
    align-items: center;
    padding: 0.5em 0.75em;
    border: none;
    border-radius: var(--button-radius);
    transition: filter var(--speed-fast);

    &.button--disabled {
      opacity: 1 !important;
    }
  }

  &::part(label)  {
    display: flex;
    gap: var(--gap-regular);
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: var(--font-size-msmall);
    font-family: var(--default-font-family);
  }

  &::part(prefix) {
    max-width: var(--gap-mlarge);
    max-height: var(--gap-mlarge);
    color: white;
    font-size: var(--font-size-regular);
  }

  &.icon::part(label) {
    margin-right: 0;
  }

  &:active {
    &::part(base) {
      filter: brightness(80%) !important;
      transition: filter 0s;
    }
  }

  &:hover:not([disabled]) {
    &::part(base) {
      filter: brightness(95%);
    }
  }


  &.rotate-caret {
    &::part(caret) {
      margin-left: var(--gap-regular);
      color: white;
      font-size: var(--font-size-regular);
      transition: transform var(--speed-fast);
      transform: rotate(-90deg);
    }

    &.open::part(caret) {
      transform: rotate(0deg);
    }
  }

  &.flip-caret {
    &::part(caret) {
      margin-left: var(--gap-regular);
      font-size: var(--font-size-regular);
      transition: transform var(--speed-fast);
      transform: rotate(0deg);
    }

    &.open::part(caret) {
      transform: rotate(-180deg);
    }
  }

  &.text {
    &::part(label) {
      color: var(--color-font-regular);
    }

    &::part(caret) {
      color: var(--color-font-regular);
    }
  }

  &.primary {
    &::part(base)  {
      background: var(--action-primary);
    }

    &::part(label) {
      color: var(--action-primary-text);
    }

    &.custom::part(base)  {
      background: var(--action-primary);
    }
  }

  &.secondary {
    &::part(base)  {
      border: 1px solid var(--button-secondary-bg-border);
      background: var(--action-secondary);
    }

    &::part(label) {
      color: var(--text-secondary);
    }

    &.custom::part(base)  {
      background: var(--action-primary);
    }

    &.custom::part(label)  {
      color: var(--action-secondary);
    }
  }

  &.neutral {
    &::part(base) {
      border: 1px solid var(--button-secondary-bg-border);
      background: var(--action-secondary);
    }

    &::part(label) {
      color: var(--text-primary);
    }
  }

  &.light {
    &::part(base) {
      background-color: var(--color-button-light);
    }

    &::part(label) {
      color: var(--color-font-regular);
    }

    &.border {
      &::part(base) {
        border: 1px solid var(--color-font-regular);
      }
    }
  }

  &.pause {
    &::part(base)  {
      background-color: var(--color-button-pause);
      box-shadow: 0 20px 30px -10px var(--color-button-pause-shadow);
    }
  }

  &.error {
    &::part(base)  {
      background-color: var(--color-button-error);
    }
  }

  &.submit {
    &::part(base)  {
      background: var(--action-primary);
    }

    &.custom::part(base)  {
      background: var(--color-button-submit-custom);
    }
  }

  &.back {
    &::part(base)  {
      min-width: 70px;
      max-width: 70px;
      min-height: var(--gap-large);
      max-height: var(--gap-large);
      background: var(--color-button-gradient-secondary-2);
      box-shadow: 0 10px 30px -10px var(--color-button-back-shadow);
    }

    &.custom {
      &::part(base) {
        background: none;
        &:hover {
          text-decoration: underline;
        }
      }


      &::part(label) {
        margin-left: 0;
        color: var(--color-button-gradient-secondary-2-custom);
        font-weight: 500;
      }
    }

    &::part(label) {
      margin-left: 2px;
      font-size: var(--font-size-msmall);
    }
  }

  &.small {
    &::part(base) {
      min-width: 100px;
      min-height: 30px;
    }
  }

  &.tiny {
    &::part(base) {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      padding: 0;
    }
  }

  &.full-width {
    width: 100%;

    &::part(base) {
      width: 100%;
    }
  }
}
