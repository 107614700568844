form {
  .invalid-message {
    color: red;
    font-size: var(--font-size-small);
  }

  margin-top: var(--gap-regular);
  .form-section {
    margin-bottom: var(--gap-large);
  }
  .form-footer {
    display: flex;
    justify-content: flex-end;
  }
  .tcs-link {
    font-size: var(--font-size-msmall);
    font-weight: bold;
    cursor: pointer;
  }
  .form-pair {
    display: flex;
    justify-content: space-between;
    width: 100%;

    sl-input, sl-textarea {
      width: calc(50% - 4px);
    }
    .country-dropdown-container {
      width: calc(50% - 4px);
    }
  }

  .country-dropdown-container {
    display: flex;
    flex-direction: column;
    color: var(--color-font-faded);
    margin-right: 8px;

    .country-label {
      font-weight: normal;
      font-size: var(--font-size-msmall);
      font-family: var(--default-font-family);
      margin: 2px 0;
    }

    sl-button {
      width: 100%;
      &::part(base) {
        justify-content: space-between;
      }

      &::part(label) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &::part(prefix) {
        display: none;
      }
    }
  }
}
