@import "../styles/breakpoints";

.plan-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: var(--card-width-main);
  height: 100%;
  border: var(--plan-card-border);
  border-radius: var(--plan-card-radius);
  background-color: var(--plan-card-bg);
  box-shadow: var(--plan-card-shadow);

  img {
    object-fit: var(--plan-card-image-fit);
    max-width: 100%;
    max-height: var(--plan-card-image-height);
  }

  &.preview {
    border-top: solid var(--color-card-preview) var(--border-radius-plan-card);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-large);
    justify-content: space-between;
    padding: var(--gap-large);

    .title-section {
      display: flex;
      flex-direction: column;
      gap: var(--gap-msmall);
      align-items: var(--plan-card-title-alignment);
      text-align: center;

      .title {
        color: var(--plan-card-title-color);
        font-weight: var(--plan-card-title-weight);
        font-size: var(--plan-card-title-size);
      }

      .description {
        color: var(--plan-card-description-color);
        font-weight: var(--plan-card-description-weight);
        font-size: var(--plan-card-description-size);
      }
    }

    .middle-section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow-y: hidden;

      .details-button {
        position: relative;
        z-index: 10;
        width: 100%;
        margin-bottom: var(--gap-regular);
        border-bottom: var(--plan-card-border);
        background-color: white;

        &::part(label) {
          font-size: var(--font-size-xs);

          @media screen and (min-width: $screen-xs) {
            font-size: var(--font-size-sm);
          }
        }

        &::part(prefix) {
          display: none;
        }

        &::part(base) {
          justify-content: space-between;
        }
      }

      .pricing-component-list-container {
        width: 100%;

        &::part(base) {
          background-color: white;
        }

        &::part(content) {
          overflow-x: hidden;
          overflow-y: auto;
          height: 280px;
        }
      }

      .details-section.no-height-limit {
        max-height: initial;
      }

      .details-section {
        display: none;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        max-height: var(--card-details-height);

        // Space for scrollbar
        padding-right: 12px;
        line-height: 1.5em;
        transition: opacity ease var(--sl-transition-medium);


        &.show-details {
          display: block;
        }

        .details-markdown {
          a {
            word-break: break-all;
          }

          img {
            max-width: 100%;
          }
        }

        &.faded {
          opacity: 0.2;
        }

        ul {
          margin-left: 18px;
          list-style-position: outside;
          list-style-type: disc;
        }

        hr {
          margin: 1em 0;
        }

        code {
          margin-bottom: 1.5rem;
        }
      }
    }

    .price-section {
      text-align: center;

      .price-container {
        .price-listing {
          margin: 0 var(--gap-regular) var(--gap-regular);

          .period {
            color: var(--color-font-regular);
          }

          .empty-period-spacer {
            height: var(--gap-msmall);
          }
        }
      }

      .quote-error {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: var(--gap-large);
        color: var(--color-font-error);

        .error-icon {
          margin-right: 10px;
        }
      }
    }
  }

  .button-section {
    display: flex;
    flex-direction: column-reverse;
    gap: var(--gap-large);
    justify-content: center;
    font-size: var(--gap-xs);

    @media screen and (min-width: $screen-xs) {
      flex-direction: row;
    }
  }

  .plan-card-mini-header {
    .price-section {
      flex-direction: initial;
    }
  }
}
