sl-checkbox {
  &::part(label) {
    color: var(--color-font-faded);
    font-size: var(--font-size-msmall);
  }

  &~.checkbox-invalid-text {
    display: none;
    font-family: var(--default-font-family);
    font-size: var(--font-size-small);
    font-weight: normal;
  }

  &[invalid]:not([disabled]).touched~.checkbox-invalid-text {
    display: block;
    color: red;
  }

  &::part(base) {
    border: none;
    padding: 0;
  }
  &::part(label) {
    display: flex;
    align-items: center;
  }

  &::part(control) {
    width: 24px;
    height: 24px;
    border-radius: 5px;
  }
}
