@import "../../styles/breakpoints";

.subscription-card {
  width: 100%;
  margin: 0;
  background-color: white;

  &.subscription-card-main {
    &.preview {
      border-top: solid var(--color-card-preview) var(--border-radius-large);
    }
  }

  .subscription-card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap-large);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: var(--gap-small) var(--gap-regular) var(--gap-regular)
      var(--gap-regular);

    .title-section {
      display: flex;
      gap: var(--gap-xsmall);
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .title {
        overflow: hidden;
        color: var(--subscription-title-color);
        font-weight: var(--subscription-title-weight);
        font-size: var(--subscription-title-size);
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .middle-section {
      display: flex;
      flex-direction: column;
      gap: var(--gap-large);
      width: 100%;

      @media screen and (min-width: $screen-lg) {
        flex-direction: row;
      }

      .subscription-image,
      .subscription-image-placeholder {
        object-fit: contain;
        width: 160px;
        height: 160px;
        margin: auto;
        border-radius: var(--border-radius-large);

        @media screen and (min-width: $screen-lg) {
          margin-left: 0;
        }
      }

      .subscription-image-placeholder {
        background: var(--color-border-light);
      }

      .details-section {
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: $screen-lg) {
          flex-direction: column;
          gap: var(--gap-large);
          justify-content: flex-start;
        }

        .subscription-detail {
          display: flex;
          flex-direction: column;
          gap: var(--gap-small);
          color: var(--color-font-dark);
          font-weight: 500;
          font-family: var(--default-font-family);
          line-height: 1.5em;

          .subscription-detail-value {
            font-size: var(--font-size-mlarge);
            margin: 0;
          }

          p {
            margin: 0;

            &:last-of-type {

            }
          }
        }
      }
    }

    .bottom-section {
      width: 100%;

      .manage-button {
        width: 100%;
        border: 0;
        border-radius: var(--radius-md);
        background-color: var(--action-primary);
        color: var(--white);

        &:hover {
          filter: brightness(1.25);
        }
      }
    }
  }

  &.subscription-add-card {
    width: 100%;
    border: 2px dashed var(--color-border-light);

    &:hover {
      background: var(--color-border-light);
      cursor: pointer;
    }

    .subscription-add-card-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      @media screen and (min-width: $screen-lg) {
        flex-direction: column;
        padding: var(--gap-large);
      }

      sl-icon {
        margin-bottom: var(--gap-regular);
        font-size: var(--font-size-large);
      }

      span {
        font-size: var(--font-size-mregular);
        font-family: var(--default-font-family);
      }
    }
  }
}
