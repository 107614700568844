sl-input, sl-textarea, sl-checkbox {
  border-radius: var(--border-radius-input);
  font-family: var(--default-font-family);

  &::part(input), &::part(textarea) {
    font-size: var(--font-size-regular);
  }

  &::part(input) {
    line-height: 1.8em;
    padding: 0 var(--gap-xsmall)
  }

  &::part(label),
  &::part(form-control-label){
    color: var(--color-input-label);
  }

  &::part(password-toggle-button) {
    font-size: var(--font-size-regular);
  }

  &::part(form-control-help-text) {
    display: none;
    font-size: var(--font-size-small);
    font-weight: normal;
  }

  &.always-show-help-text {
    &::part(form-control-help-text) {
      display: block;
    }
  }

  &[invalid]:not([disabled]).touched {
    &::part(form-control-help-text) {
      display: block;
      color: red;
    }
    &::part(base) {
      border-bottom-color: red;
      background-color: #ff000011;
    }
  }

  &.fixed-position-help-text {
    &::part(form-control-help-text) {
      position: fixed;
    }
  }

  &::part(base) {
    background-color: var(--color-input-background);
    border-radius: var(--border-radius-input);
    border: 1px solid #909090;
    padding: 0 1ex;
  }
}

.terms-conditions-help-text {
  color: red;
  font-size: var(--font-size-small);
}
