.option-dropdown {
  margin-bottom: var(--gap-regular);
  border: 2px solid var(--color-border-light);
  border-radius: var(--border-radius-regular);

  &:last-child {
    margin-bottom: 0;
  }

  .summary-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: var(--option-dropdown-height);
    padding: 0 var(--gap-regular);
    border-radius: var(--border-radius-small);
    background-color: white;
    font-size: var(--font-size-regular);
    user-select: none;

    &.openable {
      cursor: pointer;
      transition: filter var(--speed-fast);

      &:hover {
        filter: brightness(95%);
      }
    }

    .title-container {
      display: flex;
      align-items: center;

      .title-icon, img {
        margin-right: var(--gap-large);
      }

      img {
        opacity: 0.6;
      }

      .title {
        margin-right: var(--gap-small);
        font-weight: var(--sl-font-weight-semibold);
        font-family: var(--default-font-family);
      }

      .help-icon {
        font-size: var(--font-size-msmall);
        transform: translate(0, 1px);
      }
    }

    .dropdown-arrow-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--font-size-small);

      sl-icon {
        transition: transform var(--speed-medium);
      }
    }
  }

  .content-section {
    display: block;
    padding: var(--gap-regular);

    .option-description {
      color: var(--color-font-faded);
      font-size: var(--font-size-msmall);
    }
  }

  &.open {
    border-radius: 0;

  .summary-section {
    &.openable {
      border-radius: 0;
    }
  }

    .dropdown-arrow-icon-container {
      sl-icon {
        transform: rotate(90deg);
      }
    }
  }

  .warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--gap-regular);

    .icon-container {
      margin: 4px var(--gap-mlarge) 0 0;
    }

    .warning-text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .emphasis {
      font-weight: 600;
    }
  }
}
