.storefront-url-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--gap-xxlarge);

  .storefront-url-container {
    margin-bottom: var(--gap-large);
  }

  .storefront-url-button-container,
  .storefront-url-embed-container {
    .code-copy-elements {
      margin-top: var(--gap-small);

      .dropdown-section {
        display: flex;
        flex-direction: column;
        margin-top: var(--gap-mlarge);
  
        .title {
          margin-right: var(--gap-small);
          font-weight: var(--sl-font-weight-semibold);
          font-family: var(--default-font-family);
        }
  
        .dropdown-body {
          margin-top: var(--gap-small);
        }
      }

      .iframe-container {
        .dropdown-section {
          overflow: scroll;
        }
      }

      .copyable-text-area {
        margin-bottom: var(--gap-regular);
      }

      .button-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        iframe {
          overflow: hidden;
          max-height: 100px;
          margin: 4px 0 0;
        }

        .storefront-button-text-form {
          margin: 0 var(--gap-large) 0 0;
        }
      }
    }
  }

  .embed-help-message {
    margin: var(--gap-regular) 0;
  }

  .help-icon {
    margin-right: var(--gap-small);
    font-size: var(--font-size-msmall);
    transform: translate(0, 2px);
  }

  .storefront-url-specific-plan-container {
    display: flex;
    flex-direction: column;

    .plan-dropdown-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .plan-message {
        margin-bottom: var(--gap-regular);
      }

      .plan-dropdown {
        margin-bottom: var(--gap-regular);

        .plan-menu-item,
        .plan-button {
          &::part(label) {
            text-transform: none;
          }

          &::part(prefix) {
            width: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
