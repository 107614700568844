@import "../styles/breakpoints";

.portal-top-bar {
  z-index: 99;
  flex-direction: row;
  align-items: center;

  .portal-button {
    @media screen and (min-width: $screen-lg) {
      display: none;
    }

    button {
      width: 100%;
      height: 100%;
      padding: var(--gap-md);
      border: 0;
      font-size: var(--font-size-2xl);
    }
  }

  .portal-menu {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
    gap: var(--gap-3xl);
    align-self: center;
    justify-self: center;

    @media screen and (min-width: $screen-lg) {
      display: flex;
    }

    a.portal-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: var(--portal-top-bar-height);
      color: var(--color-font-dark);
      font-weight: 600;
      font-family: var(--default-font-family);
      text-decoration: none;
      transition: color var(--speed-fast);

      &.active {
        color: var(--action-primary);
      }

      &:hover {
        color: var(--action-primary);
      }

      sl-icon {
        display: none;
      }
    }
  }

  .logout-button {
    align-self: center;

    &::part(base) {
      min-width: 40px;
      max-height: 30px;
      padding: var(--gap-small);
    }

    &::part(label) {
      font-size: var(--font-size-msmall);
      text-align: right;
    }
  }

  .portal-top-bar-logo-section {
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;

    .header-logo-link {
      color: inherit;
      text-decoration: none;
    }

    #flexcheck-header-logo {
      max-width: var(--header-width-maximum);
      height: var(--header-height);

      @media screen and (min-width: $screen-lg) {
        left: var(--gap-msmall);
        display: block;
      }
    }

    .header-logo-holder {
      height: 100%;
      text-align: left;
    }
  }
}

.bm-overlay,
.bm-menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
}

.bm-menu {
  padding: var(--gap-large) 0;
  background: linear-gradient(to bottom, #ffffffb3 0%, #fff 5%);

  .bm-item-list {
    display: flex;
    flex-direction: column;

    .portal-menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: var(--gap-regular);
      color: var(--color-font-faded);
      font-weight: 500;
      font-size: var(--font-size-mregular);
      font-family: var(--default-font-family);
      text-decoration: none;

      &:hover,
      &.active {
        background: var(--color-font-faded);
        color: white;
      }

      sl-icon {
        margin-right: var(--gap-small);
        font-size: var(--font-size-mregular);
      }
    }
  }
}
