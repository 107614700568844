@import "../../styles/breakpoints";

.portal-panel {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xl);
  padding: var(--gap-2xl);
  border-radius: var(--radius-lg);
  background-color: white;

  .portal-panel-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .back {
      position: absolute;
      top: 0;
      left: 0;
    }

    .portal-panel-breadcrumbs {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      margin-right: var(--gap-regular);
    }

    .portal-panel-breadcrumb-section {
      sl-icon {
        margin: 0 var(--gap-regular) 0 var(--gap-msmall);
        font-size: var(--font-size-regular);
      }
    }

    .portal-panel-title,
    .portal-panel-breadcrumb {
      color: var(--color-font-regular);
      font-weight: bold;
      font-size: var(--font-size-xl);
      font-family: var(--default-font-family);
      line-height: 1em;
      text-decoration: none;

      @media screen and (min-width: $screen-md) {
        font-size: var(--font-size-2xl);
      }

      &.portal-panel-only-title {
        font-size: var(--font-size-2xl);
      }

      &.small {
        font-size: var(--font-size-xl);
      }
    }

    .portal-panel-top-right {
      .portal-panel-link {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        margin: var(--gap-msmall) 0 0 0;
        line-height: 1em;

        sl-icon {
          color: var(--color-font-regular);
          font-size: var(--font-size-mlarge);
        }
      }

      .portal-panel-filter-button {
        @media screen and (min-width: $screen-md) {
          &.small {
            display: inherit;

            &::part(prefix) {
              margin-right: var(--gap-small);
            }
          }

          &.tiny {
            display: none;
          }
        }

        &.small {
          display: none;
        }

        sl-badge {
          font-size: var(--font-size-small);
        }
      }
    }
  }

  .portal-panel-filters {
    box-sizing: content-box;
    width: 100%;

    .portal-panel-filters-content {
      display: flex;
      flex-wrap: wrap;
      gap: var(--gap-xl);
    }
  }

  .portal-panel-content {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: var(--font-size-regular);
  }
}
