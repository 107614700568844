@import "../styles/breakpoints";

.edit-plan-form {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xl);
  justify-content: space-between;

  .edit-plan-form-content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-4xl);
    justify-content: space-between;

    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
    }

    .options-panel-container {
      width: 100%;

      .color-picker-container {
        transform: translate(0, -8px);
      }
    }

    .preview-plan-card-panel-container {
      width: 100%;
    }
  }
}
