:root {
  --speed-fast: 0.1s;
  --speed-mfast: 0.15s;
  --speed-medium: 0.2s;
  --speed-mslow: 0.3s;
  --speed-slow: 0.4s;
  --speed-xslow: 0.5s;
  --loading-animation: shimmer 2000ms linear 0 infinite forwards;
}


@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}
