@import "../../styles/breakpoints";

.subscription-detail-overview-section {
  display: grid;
  grid-gap: var(--gap-large) 0;

  @media screen and (min-width: $screen-md) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 var(--gap-large);
  }

  .subscription-detail-mini-header {
    margin: 0;
    font-weight: 500;
    font-size: var(--font-size-md);
  }

  .subscription-detail-content {
    font-weight: 500;
    font-size: var(--font-size-2xl);
  }

  .subscription-detail-panel {
    .labelled-container-card {
      margin: 0;
    }

    .next-payment-date-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .next-payment-date-top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .next-payment-date {
          color: var(--color-font-dark);
          font-weight: 500;
          font-size: var(--font-size-mlarge);
          font-family: var(--default-font-family);

          .next-payment-date-paused {
            font-weight: normal;
            font-size: var(--font-size-regular);
          }
        }
      }
    }

    .button-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: var(--gap-md);
      border: 1px solid var(--color-font-dark);
      border-radius: var(--radius-lg);
      color: var(--color-font-dark);
      text-decoration: none;

      &:hover {
        background-color: #00000005;
      }
    }
  }
}
