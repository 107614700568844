/* stylelint-disable custom-property-empty-line-before */
:root {
  --color-cardtype-lower: #c8ebb4;
  --color-cardtype-middle: #fcd1be;
  --color-cardtype-upper: #c9c3f5;
  --color-card-preview: #FFF;

  --bf-primary-1: #8624e1;
  --bf-primary-2: #3607a6;
  --bf-gradient: linear-gradient(130deg, var(--bf-primary-1) 25%, var(--bf-primary-2) 100%);
  --color-button-gradient-primary-1: #F92C8B;
  --color-button-gradient-primary-2: #B02CD6;
  --color-button-shadow-primary: #f92c8b66;
  --color-button-gradient-secondary-1: #F7828E;
  --color-button-gradient-secondary-2: #FCB6AB;
  --color-button-shadow-secondary: #f7828e66;
  --color-button-neutral: #9B9B9B;
  --color-button-light: #F9FAFD;
  --color-button-shadow-neutral: #9B9B9B66;
  --color-button-error: #fa5e5d;
  --color-button-error-shadow: #fa5e5d66;
  --color-button-cancel: #D55F5F;
  --color-button-cancel-shadow: #d55f5f4d;
  --color-button-pause: #EDAB47;
  --color-button-pause-shadow: #edab474d;
  --color-button-submit: #8626E1;
  --color-button-submit-shadow: #8626ff66;
  --color-button-back-shadow: #fcb6ab66;
  --color-border-light: #f3f3f3;
  --color-blockquote-light: #e3e3e3;
  --card-main-shadow: #6e82d066;
  --plan-card-mini-shadow: var(--card-main-shadow);

  // TODO: Multiple variables of the same type unnecessary
  --color-button-gradient-primary-1-custom: #666;
  --color-button-gradient-primary-2-custom: #333;
  --color-button-shadow-primary-custom: #6666;
  --color-button-gradient-secondary-1-custom: #666;
  --color-button-gradient-secondary-2-custom: #666;
  --color-button-shadow-secondary-custom: #6666;
  --color-button-submit-custom: #666;
  --color-button-submit-shadow-custom: #6666;

  --color-font-dark: #333;
  --color-font-regular: #666;
  --color-font-light: #C1C1C1;
  --color-font-faded: #3e3e3e80;
  --color-font-error: #B30000;

  --color-input-background: #fff;
  --color-input-label: var(--color-font-regular);

  --color-merchant-primary: #6B1FCC;
  --color-merchant-table-row-1: white;
  --color-merchant-table-row-2: #F4F1F8;

  // TODO: Use this in skeleton.css
  --color-link: #1EAEDB;

  --white: #FFF;
  --light-color-primary: var(--white);
  --light-button-primary-bg: #3A82F7;
  --light-button-primary-bg-hover: #5293FD;
  --light-button-primary-bg-active: #1E73FE;
  --light-button-primary-bg-disabled: #EAF2FF;
  --dark-color-primary: #0B1821;
  --dark-button-primary-bg: #00E3E3;
  --dark-button-primary-bg-hover: #0FF;
  --dark-button-primary-bg-active: #01C9C9;
  --dark-button-primary-bg-disabled: #52606D;
  --color-secondary: #52606D;
  --button-secondary-bg: var(--white);
  --button-secondary-bg-border: #CBD2D9;
  --button-secondary-bg-hover: #F6F6F7;
  --button-secondary-bg-active: #F1F2F3;
  --button-secondary-bg-disabled: #F2F4F6;
  --button-ghost-color: #01C9C9;
  --button-ghost-border: var(--button-ghost-color);
  --button-ghost-color-hover: #00E3E3;
  --button-ghost-color-border: var(--button-ghost-color-hover);
  --button-ghost-color-active: #009C9C;
  --button-ghost-bg-active: #E4FFFF;
  --button-ghost-color-active-border: var(--button-ghost-color-active);
  
  // palette
  --teal-1: #0FF;
  --teal-2: #00E3E3;
  --teal-3: #01C9C9;
  --teal-4: #009C9C;
  --teal-5: #245959;
  
  --grey-1: #0B1821;
  --grey-2: #323F4B;
  --grey-3: #52606D;
  --grey-4: #7B8794;
  --grey-5: #9AA5B1;
  --grey-6: #CBD2D9;
  --grey-7: #E4E7EB;
  --grey-8: #F2F4F6;
  --grey-9: #F9F9FA;
  --grey-transparent: #3333;
  
  --success: #22CC7A;
  --neutral: #47B6D9;
  --warning: #F19D39;
  --error: #D94747;
  
  --text-primary: #37424B;
  --text-secondary: #A8A8A8;
  --text-disabled: #BCCCE2;
  --text-error: var(--error);
  --text-warning: #916A00;
  --text-success: #008060;
  --text-highlight: #347C84;
  --text-primary-dark: var(--white);
  --text-secondary-dark: var(--grey-6);
  
  --action-primary: var(--bf-primary-2);
  --action-primary-text: var(--white);
  --action-primary-hover: #5293FD;
  --action-primary-active: #1E73FE;
  --action-disabled: var(--grey-8);
  
  --action-primary-dark: #1D3B51;
  --action-primary-hover-dark: #21435C;
  
  --action-secondary: var(--white);
  --action-secondary-hover: #F6F6F7;
  --action-secondary-active: #F1F2F3;
  
  --action-ghost: var(--teal-3);
  --action-ghost-hover: var(--teal-2);
  --action-ghost-active: var(--teal-4);
  
  --icon-accent-1: var(--teal-1);
  --icon-accent-2: var(--teal-3);
  
  --icon-primary: #A9ADB1;
  --icon-primary-hover: var(--grey-4);
  --icon-primary-active: var(--grey-5);
  --icon-disabled: #D2D7DE;
  --icon-success: var(--success);
  --icon-neutral: var(--neutral);
  --icon-warning: var(--warning);
  --icon-error: var(--error);

  // Commonly customised
  --content-bg: var(--white);
  --plan-card-border-color: var(--grey-transparent);
  --plan-card-bg: var(--content-bg);
  --plan-card-shadow: 0;
  --plan-card-title-color: var(--color-font-dark);
  --plan-card-description-color: var(--color-font-regular);
  --subscription-title-color: var(--color-font-dark);
  
  --loading-gradient: linear-gradient(to right, var(--grey-7) 0%, var(--grey-9) 50%, var(--grey-7) 100%);
}
