.storefront-embed-form {
  margin-top: var(--gap-small);

  .form-section {
    display: flex;
    margin-bottom: 0;

    .width-section {
      display: flex;
      flex-direction: column;
    }

    sl-input {
      margin-right: var(--gap-large);

      &::part(form-control) {
        display: flex;
        flex-direction: column;
      }
      &::part(base) {
        max-width: 150px;
      }
    }
  }
}
