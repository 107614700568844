.price-text {
  color: var(--color-font-dark);
  font-weight: bold;
  font-size: var(--font-size-mlarge);

  p {
    margin: 0;
  }

  .old-price {
    font-size: var(--font-size-msmall);
    text-decoration: line-through;
    opacity: 0.3;
  }

  .small {
    padding-top: 5px;
    font-size: var(--font-size-msmall);
  }

  &.loading {
    opacity: 0.5;
  }
}

.price-right {
}

.is-sentence,
.is-sentence p {
  display: inline;
}
