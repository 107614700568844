@import "../../styles/breakpoints";

sl-dialog {
  &::part(panel) {
    z-index: var(--sl-z-index-dialog);
    border-radius: var(--border-radius-dialog);
  }

  &::part(close-button) {
    font-size: var(--font-size-mlarge);
  }

  &.preview {
    &::part(base) {
      margin-left: var(--merchant-menu-width);
    }
  }

  .dialog-buttons {
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
    align-items: baseline;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      justify-content: flex-end;

      &.apart {
        justify-content: space-evenly;
      }
    }


    .loading-button-container,
    sl-button {
      width: 100%;
    }
  }
}
