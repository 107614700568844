@import "../styles/breakpoints";

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: var(--gap-2xl);
  border-radius: var(--radius-md);
  background-color: white;

  @media screen and (min-width: $screen-md) {
    width: $screen-md; //Ensure no jump when resizing, just make this a max.
    padding: var(--gap-4xl);
  }

  .step-container-header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
    align-items: center;
    width: 100%;

    .back {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      gap: var(--gap-sm);
      align-items: center;
      color: inherit;
      text-decoration: none;

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }

    .step-count {
      margin-top: var(--gap-xlarge);
      color: var(--color-font-faded);
      font-weight: bold;
      font-size: var(--font-size-regular);
      font-family: var(--default-font-family);
      text-transform: uppercase;

      @media screen and (min-width: $screen-sm) {
        margin-top: 0;
      }
    }

    .step-title {
      color: var(--color-font-dark);
      font-size: var(--font-size-large);
      text-align: center;
    }
  }

  .step-container-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: var(--gap-large) 0;
    font-size: var(--font-size-regular);

    & > * {
      margin-bottom: var(--gap-regular);
    }

    .step-container-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
