@import 'styles/breakpoints';

.user-container {
  display: flex;
  flex-direction: column;

  .store-closed, .page-not-found, .error-fallback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .message-container {
      margin-bottom: var(--gap-xxlarge);
      padding: var(--gap-regular) var(--gap-large);
      border-radius: var(--border-radius-large);
      background-color: #fff9;
      box-shadow: 0 2px 30px -5px var(--plan-card-mini-shadow);
      font-size: var(--font-size-large);
      font-family: var(--default-font-family);
      text-align: center;
    }

    .error-fallback-alert {
      margin-bottom: var(--gap-xxlarge);

      &::part(base) {
        padding: var(--gap-regular);
        border-radius: var(--border-radius-large);
      }

      .error-fallback-title-container {
        display: flex;
        align-items: center;

        .error-fallback-title-icon {
          margin-right: var(--gap-regular);
          color: var(--sl-color-danger-400);
          font-size: var(--font-size-mlarge);
        }

        .error-fallback-title {
          font-size: var(--font-size-large);
          font-family: var(--default-font-family);
        }
      }
    }
  }

  .store-closed .message-container {
    background-color: #fff9;
    color: var(--color-font-regular);
  }

  .page-not-found .message-container {
    background-color: #fff2;
    color: white;
  }

  .page {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-3xl);
    width: 100%;
    max-width: 1600px;
    height: 100%;
  }
}
