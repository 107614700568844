@import '../styles/breakpoints';

.portal-page {
  display: flex;
  flex-direction: column;
  gap: var(--gap-3xl);
  width: 100%;
  height: 100%;

  &::after {
    // Adds a white semi-transparent layer to fade background
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff80;
  }
}
