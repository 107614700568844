.subscription-pause-dialog {
  --width: 800px;

  &::part(title) {
    font-size: var(--font-size-large);
    font-family: var(--default-font-family);
    margin-left: var(--gap-regular);
    font-weight: bold;
  }

  &::part(body) {
    padding: 0 var(--gap-large);
  }

  &::part(panel) {
    font-weight: normal;
  }

  &::part(footer) {
    padding: var(--gap-large);
  }

  .subscription-pause-dialog-content {
    .pause-sentence {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: var(--gap-regular);

      &.current-resumption {
        padding-bottom: var(--gap-large);
        margin-bottom: var(--gap-large);
        border-bottom: 2px solid var(--color-border-light);
      }
    }

    .resume-date-options {
      display: flex;
      flex-direction: column;

      .resume-date-radio-group {
        display: flex;
        flex-direction: column;

        sl-radio {
          &::part(label) {
            font-size: var(--font-size-regular);
            font-family: var(--default-font-family);
          }
        }
      }

      .resume-date-picker {

        &.faded {
          opacity: 50%;
        }

        > div {
          border: 1px solid var(--color-border-light);
          border-radius: var(--border-radius-regular);
          margin: var(--gap-small) 0 var(--gap-large) 0;
          width: 300px;

          .nice-dates-day {
            height: 30px !important;
          }
          .nice-dates-grid {
            height: 180px !important;
          }
        }
      }
    }
  }
}
