sl-color-picker {
  // Shoelace variable overrides
  --grid-height: 120px;
  --grid-width: 150px;
  --slider-height: 8px;
  --slider-handle-size: 10px;
  --grid-handle-size: 12px;

  // Apply transforms to correctly enlarge and position color picker dropdown, then apply inverse transforms to dropdown trigger to keep it in original position
  --color-picker-scale: 1.6;
  --color-picker-translate-x: -118px;
  --color-picker-translate-y: -30px;

  transform: scale(var(--color-picker-scale)) translate(var(--color-picker-translate-x), var(--color-picker-translate-y));
  &::part(trigger) {
    transform: translate(calc(-1*var(--color-picker-translate-x)), calc(-1*var(--color-picker-translate-y))) scale(calc(1/var(--color-picker-scale)));
  }
  sl-dropdown {
    transform: translate(calc(-1*var(--color-picker-translate-x)), calc(-1*var(--color-picker-translate-y))) scale(calc(1/var(--color-picker-scale)));
  }
}