@import "styles/breakpoints";
@import "styles/reset";
@import "styles/skeleton/skeleton";

@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap";

@import "styles/colors";
@import "styles/sizes";
@import "styles/transitions";

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
}

body {
  min-width: var(--body-minimum-width);
  height: 100%;
  min-height: 100vh;
  margin: 0;
  color: #666;
  font-family: var(--default-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 800;
}

.textual-space-before {
  margin-left: var(--textual-space);
}

.user-background {
  background-image: url("assets/bottom-curve-image.svg"),
    linear-gradient(#ffffff7f, #ffffff7f),
    url("assets/background-transparent.png");
  background-attachment: local, local, fixed, fixed;
  background-position: top 700px center, top 950px center, top center,
    top center;
  background-size: 100% 250px, cover, cover, cover;
  background-repeat: no-repeat;
  overscroll-behavior: none;
}

.merchant-background {
  background-color: white;
  background-image: none;
  overscroll-behavior: none;
}

b,
strong {
  color: var(--color-font-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

blockquote {
  margin: var(--gap-small) 0;
  padding-left: var(--gap-mlarge);
  border-left: 6px solid var(--color-blockquote-light);
}

// Override shoelace styling that hides dehydrated elements (causes all shoelace elements to be hidden on redux state change)
// TODO: remove sl-form and sl-menu-divider?
sl-color-picker,
sl-select,
sl-alert,
sl-avatar,
sl-details,
sl-dialog,
sl-drawer,
sl-menu-item,
sl-badge,
sl-checkbox,
sl-form,
sl-menu-divider,
sl-menu-label,
sl-progress-bar,
sl-progress-ring,
sl-radio,
sl-range,
sl-switch,
sl-tab,
sl-tab-group,
sl-tab-panel,
sl-textarea,
sl-tooltip,
sl-button,
sl-icon,
sl-tag,
sl-menu,
sl-input,
sl-dropdown,
sl-spinner {
  visibility: inherit;
}

// Override changes between shoelace@2.0.0-beta.6 and shoelace@2.0.0-beta.22 (https://github.com/shoelace-style/shoelace/commit/4ceea86197faf19cb8630f260eb17db4a9db9e45)
// Measurements were changed from px to rem, on the assumption that root font-size is 16px, but it is 10px in this project
:root {
  --sl-input-height-small: 3rem;
  --sl-input-height-medium: 4rem;
  --sl-input-height-large: 5rem;
  --sl-input-spacing-small: 1rem;
  --sl-input-spacing-medium: 1.2rem;
  --sl-input-spacing-large: 1.4rem;
  --sl-toggle-size: 1.6rem;
  --sl-color-primary-hue: 266.4;
  --sl-color-primary-saturation: 73.6%;
}

.button-as-link {
  height: auto;
  line-height: initial;
  margin: 0;
  padding: 0;
  border: none;
  background: none;

  /* override as input has OS specific font-family */
  color: var(--color-link);
  font-family: var(--default-font-family);
  text-decoration: underline;
  cursor: pointer;
}

.gap-above {
  margin-top: var(--gap-small);
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}
