.invalid sl-dropdown {
  background-color: #ffdede;

  sl-button {
    &::part(base) {
      background-color: #ffdede;
    }
  }
}

sl-dropdown {
  background-color: white;

  sl-button, sl-menu-item {
    font-family: var(--default-font-family);

    &::part(label) {
      color: var(--color-font-dark);
      font-weight: normal;
    }

    &::part(prefix) {
      max-width: var(--gap-mlarge);
      max-height: var(--gap-mlarge);
      margin-right: var(--gap-small);
      color: var(--color-font-dark);
      font-size: var(--font-size-regular);
    }
  }

  sl-button {
    &::part(base) {
      min-width: 0;
      min-height: 0;
      border: none;
      border-bottom: solid #BEC2D8 3px;
      border-radius: var(--border-radius-dropdown);
      background-color: var(--color-input-background);
    }

    &::part(label) {
      margin-right: var(--gap-regular);
    }

    &::part(caret) {
      font-size: var(--font-size-regular);
    }
  }

  sl-menu-item {
    &::part(base) {
      display: flex;
      align-items: center;
    }
  }
}
