.subscription-upgrade-dialog {
  --width: 800px;

  &::part(title) {
    font-size: var(--font-size-large);
    font-family: var(--default-font-family);
    margin-left: var(--gap-regular);
    font-weight: bold;
  }

  &::part(body) {
    padding: 0 var(--gap-large);
  }

  &::part(panel) {
    font-weight: normal;
  }

  &::part(footer) {
    padding: var(--gap-large);
  }

  .subscription-upgrade-dialog-content {
    .upgrade-summary {
      margin: var(--gap-regular) 0;
      display: grid;
      grid-template-columns: auto auto 24px 1fr;

      .upgrade-summary-line-name {
        font-family: var(--default-font-family);
        font-size: 18px;
        font-weight: 500;
        margin-right: var(--gap-xlarge);
      }

      .upgrade-summary-line-old-value, .upgrade-summary-line-new-value {
        font-size: 18px;
        font-weight: bold;
        margin-right: var(--gap-small);
      }

      .upgrade-summary-line-old-value {
        color: var(--color-font-faded);
        text-align: right;
      }

      .upgrade-summary-line-new-value {
        color: var(--sl-color-success-40);
      }

      .upgrade-summary-line-arrow {
        sl-icon {
          font-size: 18px;
          transform: translate(0, 6px);
          margin-right: var(--gap-small);
        }
      }
    }

    .upgrade-sentence {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .price-text {
        font-size: var(--font-size-regular);
        font-weight: bold;
        margin: 0 var(--gap-small);
        display: flex;
        align-items: center;
      }
    }

    .upgrade-totals {
      display: flex;
      flex-direction: column;

      .upgrade-total {
        display: grid;
        grid-template-columns: auto 1fr auto;

        & > span {
          display: flex;
          align-items: flex-end;
          margin-right: var(--gap-large);
          font-family: var(--default-font-family);
          font-size: 18px;
          font-weight: 500;
          line-height: 1em;
        }

        .upgrade-total-line {
          border-bottom: 2px solid var(--color-border-light);
          width: 100%;
        }

        .price-text {
          margin-left: var(--gap-large);
          font-size: var(--font-size-mmlarge);
          line-height: 2em;
        }
      }
      .upgrade-total-period-text {
        width: 100%;
        font-size: var(--font-size-msmall);
        color: var(--color-font-faded);
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
