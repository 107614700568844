.markdown-text-area-container {
  .markdown-text-area {
    .markdown-tip {
      display: flex;
      align-items: center;

      .markdown-tip-icon {
        min-width: var(--font-size-regular);
        margin-right: var(--gap-small);
      }

      p {
        margin: 0;
      }
    }
  }

  .preview-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--gap-regular);
  }

  .markdown-editor {
    border: none;
    font-family: var(--default-font-family);

    > div:last-child {
      border: solid #BEC2D8 1px;
      border-bottom-width: 3px;
      background-color: var(--color-input-background);
      font-size: var(--font-size-msmall);

      div[data-contents=true] {
        min-height: 120px;
      }

      li {
        list-style-position: outside;
      }

      span {
        max-width: 300px !important;
        max-height: 200px !important;
        background-size: 100% !important;
      }
    }

    > div:first-child {
      border-bottom-width: 0;

      button {
        border-color: var(--color-font-light);
      }

      div > div:first-child {
        > button {
          border-radius: var(--border-radius-regular) 0 0 var(--border-radius-regular);
        }
      }

      div > div:last-child {
        > button {
          border-radius: 0 var(--border-radius-regular) var(--border-radius-regular) 0;
        }
      }

      div > div:only-child {
        > button {
          border-radius: var(--border-radius-regular);
        }
      }

      div > span > span {
        border-color: var(--color-font-light);
        border-radius: var(--border-radius-regular);
      }
    }
  }
}
