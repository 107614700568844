@import "../styles/breakpoints";

.merchant-top-bar {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--merchant-top-bar-height);
  background-image: url("../assets/top-curve-image.svg"), var(--bf-gradient);
  background-attachment: local;
  background-position: left, center;
  background-size: 33% var(--merchant-top-bar-height), cover;
  background-repeat: no-repeat;
  color: var(--white);
  text-align: center;

  @media screen and (min-width: $screen-lg) {
    margin-left: var(--merchant-menu-width);
    background-size: 50% var(--merchant-top-bar-height), cover;
  }

  &-header {
    display: flex;
    gap: var(--gap-regular);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: var(--font-size-lg);

    .logo {
      height: var(--font-size-lg);
      fill: var(--white);
    }
  }

  .merchant-menu-button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    @media screen and (min-width: $screen-lg) {
      display: none;
    }

    sl-icon {
      transform: scale(2);
    }
  }
}
