.status-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  border-radius: var(--radius-md);
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  user-select: none;

  &.success {
    background-color: var(--sl-color-success-400);
  }

  &.warning {
    background-color: var(--sl-color-warning-400);
  }

  &.danger {
    background-color: var(--sl-color-danger-400);
  }
}
