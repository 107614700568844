.plan-active-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .plan-active-checkbox-spinner {
    color: var(--color-merchant-primary);
    font-size: var(--font-size-md);
  }
}
