.copyable-text-area {
  width: 100%;
  position: relative;
  padding: 10px 36px 10px 0;
  background-color: var(--color-input-background);
  border: none;
  border-bottom: solid #BEC2D8 3px;

  .copy-button {
    position: absolute;
    right: var(--gap-small);
    top: var(--gap-small);
  }

  pre {
    margin: 0;
    .copyable-text-area-content {
      font-family: 'Roboto Mono', sans-serif;
      font-size: var(--font-size-msmall);
      color: var(--color-font-faded);
      background: transparent;
      border: none;
      padding: 0 var(--gap-xlarge) 0 0;
      overflow: scroll;
      min-height: 22px;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
