@import "styles/breakpoints";

.user-container,
.portal-container {
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;
  padding: var(--gap-xl);

  @media screen and (min-width: $screen-sm) {
    padding: var(--gap-2xl);
  }
  @media screen and (min-width: $screen-md) {
    padding: var(--gap-3xl);
  }
  @media screen and (min-width: $screen-lg) {
    padding: var(--gap-4xl);
  }
  @media screen and (min-width: $screen-xl) {
    max-width: 1600px;
    margin: auto;
    padding: var(--gap-4xl) calc(var(--gap-4xl) * 2);
  }
}

.portal-container {
  background-color: #eee;
}
