@import "../styles/breakpoints";

.plan-group-card {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: var(--plan-group-card-size-sm);
  height: var(--plan-group-card-size-sm);
  border-radius: var(--radius-lg);
  scroll-snap-align: center;

  @media screen and (min-width: $screen-md) {
    width: var(--plan-group-card-size-md);
    height: var(--plan-group-card-size-md);
    border-radius: var(--radius-xl);
  }

  @media screen and (min-width: $screen-lg) {
    width: var(--plan-group-card-size-lg);
    height: var(--plan-group-card-size-lg);
  }
  
  .link {
    display: block;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 99;
      background: linear-gradient(to top, #0008, transparent);
    }

    .background {
      position: absolute;
      inset: 0;
      z-index: 9;
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .content {
      position: absolute;
      inset: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
      gap: var(--gap-sm);
      justify-content: flex-end;
      padding: var(--gap-lg);
      color: var(--white);

      h2 {
        font-weight: 500;
        font-size: var(--font-size-xl);

        @media screen and (min-width: $screen-md) {
          font-size: var(--font-size-3xl);
        }
      }

      p {
        margin: 0;
      }
    }
  }
}
