.options-panel-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .options-panel-title {
    margin: var(--gap-regular) 0;
    font-size: var(--font-size-mlarge);
  }

  .options-panel {
    width: 100%;
    min-height: 100px;
    border-radius: var(--border-radius-large);
    background-color: white;
  }
}
