.payment-gateway-options {
  .payment-gateway-options-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .payment-gateway-options-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      sl-dropdown {
        margin-right: var(--gap-xlarge);

        sl-button, sl-menu-item {
          .payment-gateway-logo {
            max-width: 100%;
            max-height: 100%;
          }

          .payment-gateway-icon {
            margin: 0;
          }

          &::part(prefix) {
            margin-right: var(--gap-regular);
          }
        }
      }

      .payment-gateway-message {
        sl-icon {
          font-size: 8px;
          margin-right: var(--gap-small);
        }

        &.active {
          sl-icon {
            &::part(base) {
              color: var(--sl-color-success-400);
            }
          }
        }

        &.inactive {
          sl-icon {
            &::part(base) {
              color: var(--sl-color-danger-400);
            }
          }
        }
      }
    }

    .payment-gateway-link-section {
    }
  }
  .payment-gateway-options-additional {
    margin-top: var(--gap-large);

    .title {
      font-family: var(--default-font-family);
      font-weight: bold;
      font-size: var(--font-size-regular);
    }
    .content {
      width: 100%;
      border: 2px solid var(--color-border-light);
      border-radius: var(--border-radius-regular);
      padding: var(--gap-regular);
    }
  }
}
