sl-alert.alert-toast {
  --box-shadow: var(--sl-shadow-large);
  margin: var(--sl-spacing-medium);
  max-width: 90%;
  min-width: 300px;
  pointer-events: all;

  &::part(base) {
    border-width: var(--border-width-regular) 0 0 0;
  }

  &::part(message) {
    max-width: 100%;
    max-height: 110px;
    overflow: auto;
  }

  &::part(close-button) {
    font-size: var(--font-size-regular);
    font-weight: normal;
    padding: 0;
  }
}
