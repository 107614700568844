.login-form {
  width: 100%;
  max-width: 400px;
  padding: var(--gap-large) var(--gap-xxlarge);
  border-radius: var(--border-radius-large);
  background-color: white;

  .login-form-title {
    font-weight: bold;
    font-size: var(--font-size-large);
    font-family: var(--default-font-family);
    text-align: center;
  }

  .login-form-text {
    margin-bottom: var(--gap-large);
    font-weight: lighter;
    font-size: var(--font-size-regular);
    font-family: var(--default-font-family);
    text-align: center;
  }

  .form-input-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    .form-input {
      width: 100%;
      margin-right: var(--gap-large);
    }
  }

  .password-reset-link {
    display: flex;
    justify-content: flex-end;
    font-size: var(--font-size-small);
  }

  .checkbox-container {
    margin: var(--gap-lg) 0;

    .error {
        color: var(--error);
    }

    div {
      line-height: 1;
    }
  }

  .form-footer {
    justify-content: center;
    margin-bottom: var(--gap-large);

    &:last-child {
      margin-bottom: var(--gap-regular);
    }
  }
}
