@import "../styles/breakpoints";

.plans-grid-container {
  overflow-x: scroll;
  width: 100vw;
  padding: var(--gap-xl);
  padding-right: 0;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  scrollbar-width: none;

  @media screen and (min-width: $screen-sm) {
    padding: var(--gap-2xl);
    padding-right: 0;
  }

  @media screen and (min-width: $screen-md) {
    padding: var(--gap-3xl);
    padding-right: 0;
  }

  @media screen and (min-width: $screen-lg) {
    padding: var(--gap-4xl);
    padding-right: 0;
  }

  @media screen and (min-width: $screen-xl) {
    padding: var(--gap-4xl) 0 var(--gap-4xl) calc(var(--gap-4xl) * 2);
  }

  &::-webkit-scrollbar {
    display: none;
    inline-size: 0 !important;
  }

  .plans-grid {
    display: flex;
    gap: var(--gap-lg);
    width: 100%;

    @media screen and (min-width: $screen-md) {
      gap: var(--gap-2xl);
    }

    &::after {
      content: "";
      align-self: stretch;
      padding-inline-end: calc(var(--gap-md) / 2);
      
      @media screen and (min-width: $screen-sm) {
        padding-inline-end: var(--gap-lg);
      }
      
      @media screen and (min-width: $screen-lg) {
        padding-inline-end: var(--gap-2xl);
      }
      @media screen and (min-width: $screen-xl) {
        padding-inline-end: calc(var(--gap-3xl) * 2);
      }
    }

    &-error {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      margin: var(--gap-xxlarge) 0;
      padding: var(--gap-regular) var(--gap-large);
      border-radius: var(--border-radius-large);
      background-color: #fff9;
      color: var(--color-font-error);
      box-shadow: 0 2px 30px -5px var(--plan-card-mini-shadow);
      font-size: var(--font-size-regular);
      font-family: var(--default-font-family);

      .error-icon {
        margin-right: var(--gap-msmall);
        margin-bottom: var(--gap-regular);
        transform: translate(0, 6px);
      }

      .error-message {
        text-align: center;
      }
    }
  }
}
