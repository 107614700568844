sl-alert {
  &::part(base) {
    border-radius: var(--border-radius-alert);
    border-top-width: var(--border-width-regular);
  }

  &::part(message) {
    font-size: var(--font-size-msmall);
    font-weight: normal;
    font-family: var(--default-font-family);
  }

  &::part(icon) {
    font-size: var(--font-size-regular);
    font-weight: normal;
  }
}
