@import "../styles/breakpoints";

.warning-dialog {
  --width: 400px;

  &::part(base) {
    @media screen and (min-width: $screen-lg) {
      left: var(--merchant-menu-width);
    }
  }

  &::part(body) {
    padding: var(--gap-large) var(--gap-xlarge) 0 var(--gap-xlarge);
  }

  &::part(panel) {
    font-weight: normal;
  }

  &::part(footer) {
    padding: var(--gap-large);
  }
}
