.crop-dialog {
  --width: 800px;

  &::part(title) {
    font-size: var(--font-size-mlarge);
    font-family: var(--default-font-family);
    margin-left: var(--gap-regular);
  }

  &::part(base) {
    left: var(--merchant-menu-width);
  }

  &::part(body) {
    padding: 0 var(--gap-large);
  }

  &::part(panel) {
    font-weight: normal;
  }

  &::part(footer) {
    padding: var(--gap-large);
  }

  .crop-view {
    width: 100%;
    height: 100%;

    img {
      display: block;

      /* This rule is very important, please don't ignore this */
      max-width: 100%;
    }
  }

  sl-alert.crop-description {
    margin: var(--gap-regular) 0;

    &::part(base) {
      border-color: var(--sl-color-primary-50);
    }
  }
}
