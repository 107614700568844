.spinner-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  sl-spinner {
    --stroke-width: var(--gap-small);

    font-size: 96px;
  }
}
