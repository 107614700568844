@import "../styles/breakpoints";

.merchant-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  width: var(--merchant-menu-width);
  height: 100%;
  padding: var(--gap-xl);
  padding-right: var(--gap-4xl);
  background-color: var(--grey-9);
  color: var(--gewy-5);
  font-weight: 500;
  font-size: var(--font-size-sm);
  transition: all 400ms;
  transform: translateX(var(--merchant-menu-width));

  @media screen and (min-width: $screen-lg) {
    left: 0;
    padding: var(--gap-xl);
    transition: all 0s;
  }

  &.showing {
    transform: translateX(0);
  }

  .merchant-close-button {
    position: absolute;
    top: var(--gap-small);
    right: var(--gap-small);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    font-size: var(--font-size-2xl);

    @media screen and (min-width: $screen-lg) {
      display: none;
    }
  }

  .trading-status {
    display: flex;
    align-items: baseline;

    sl-icon {
      margin-right: var(--gap-small);
      font-size: 8px;
    }

    &.open {
      sl-icon {
        &::part(base) {
          color: var(--sl-color-success-400);
        }
      }
    }

    &.closed {
      sl-icon {
        &::part(base) {
          color: var(--sl-color-danger-400);
        }
      }
    }

    .trading-status-text {
      font-weight: 600;
      font-size: var(--font-size-small);
      text-transform: uppercase;
    }
  }

  .merchant-menu-title {
    font-size: var(--font-size-md);

    .merchant-logo {
      width: 100%;
    }
  }

  .menu-item-container {
    margin: 0;

    &.advanced {
      margin: 1rem 0;
    }

    .merchant-menu-item {
      display: flex;
      gap: var(--gap-sm);
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 30px;
      margin: 0;
      padding: 0;
      border-radius: var(--radius-md);
      text-decoration: none;
      transition: all 100ms;

      &:hover {
        background-color: var(--grey-8);
      }

      &.active {
        color: var(--action-primary);
        fill: var(--action-primary);
      }

      &.merchant-menu-button {
        border: 0;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
      }
    }
  }
}
