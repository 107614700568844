.payment-retry-dialog {
  --width: 800px;

  &::part(title) {
    font-size: var(--font-size-large);
    font-family: var(--default-font-family);
    margin-left: var(--gap-regular);
    font-weight: bold;
  }

  &::part(body) {
    padding: 0 var(--gap-large);
  }

  &::part(panel) {
    font-weight: normal;
  }

  &::part(footer) {
    padding: var(--gap-large);
  }

  .payment-retry-dialog-content {
    .retry-sentence {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: var(--gap-regular);
    }

    .payment-method-card {
      margin-bottom: var(--gap-xlarge);
    }
  }
}
