.checkout-status-dialog {
  --width: 400px;

  &::part(title) {
    font-size: var(--font-size-mlarge);
    font-family: var(--default-font-family);
    margin-left: var(--gap-regular);
    font-weight: 500;
  }

  &::part(body) {
    padding: 0 var(--gap-large);
  }

  &::part(panel) {
    font-weight: normal;
  }

  &::part(footer) {
    padding: var(--gap-large);
  }

  .checkout-status-dialog-content {
    .checkout-status-line {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: var(--gap-regular);
    }

    .checkout-status-line-border {
      margin: 0 var(--gap-regular);
      border-bottom: 2px solid var(--color-border-light);
      //width: 100%;
    }
  }
}
