.labelled-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);

  .labelled-container-label {
    font-weight: 700;
    font-size: var(--font-size-xl);

    &.nested {
      font-weight: 600;
      font-size: var(--font-size-lg);
    }
  }

  .labelled-container-card {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
    width: 100%;
    height: 100%;
    margin-bottom: var(--gap-regular);
    padding: 0;
    border: 0;
    text-align: start;

    .labelled-container-card-icon {
      position: absolute;
      top: var(--gap-regular);
      right: var(--gap-regular);
      font-size: var(--font-size-regular);
      filter: brightness(100%) saturate(100%);

      sl-icon::part(base) {
        font-size: var(--font-size-regular);
      }
    }

    &.selectable {
      cursor: pointer;
    }

  
    &.max-height {
      height: 100%;

      > .labelled-container-card {
        height: 100%;
      }
    }
  
    &.big-label {
      > .labelled-container-label {
        font-size: var(--font-size-mlarge);
      }
    }

    &.small-label {
      > .labelled-container-label {
        font-size: var(--font-size-msmall);
      }
    }
  
    &.faded-label {
      > .labelled-container-label {
        color: var(--color-font-faded);
        font-weight: normal;
      }
    }

    &.selected {
      border: 0;
      border-radius: 0;
      background: var(--color-font-dark);
      color: white;

      span, .labelled-container-label, .labelled-container-card, .payment-method-card, .payment-method-count {
        color: white;
      }

      .labelled-container-card-icon {
        filter: brightness(0) saturate(100%) invert(100%);
      }

      .payment-method-card-icon {
        filter: brightness(0) saturate(100%) invert(100%);
      }
    }
  }

  
}
