.login-header {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  padding-top: 8rem;
  color: var(--action-primary);
  
  .logo {
    width: 300px;
    fill: var(--action-primary);
  }
}
