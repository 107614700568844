@import "../../styles//breakpoints";

.header-logo-holder {
  text-align: center;

  @media screen and (min-width: $screen-md) {
    text-align: left;
  }

  img {
    max-height: var(--header-height);
  }

  .title {
    margin: 0;
  }
}