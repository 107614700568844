.square-extra-details-form {
  .country-button, .country-menu-item {
    &::part(base) {
      justify-content: space-between;
    }

    &::part(prefix) {
      display: none;
    }

    &::part(label) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .country-button {
    width: 100%;
  }

  .country-dropdown-container {
    display: flex;
    flex-direction: column;
    color: var(--color-font-faded);

    .country-label {
      font-weight: normal;
      font-size: var(--font-size-msmall);
      font-family: var(--default-font-family);
      margin-top: 3px;
    }
  }

  .form-section-title {
    font-weight: bold;
    font-family: var(--default-font-family);
    font-size: var(--font-size-regular);
    margin-bottom: var(--gap-small);
  }

  .form-pair {
    display: flex;

    sl-input, .country-dropdown-container {
      float: left;
      width: calc((100% - 32px) / 3);
      padding: 0;
      margin: 0 16px 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}
