.plans-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-large);
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 4rem;

  &-error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: var(--gap-xxlarge) 0;
    padding: var(--gap-regular) var(--gap-large);
    border-radius: var(--border-radius-large);
    background-color: #fff9;
    color: var(--color-font-error);
    box-shadow: 0 2px 30px -5px var(--plan-card-mini-shadow);
    font-size: var(--font-size-regular);
    font-family: var(--default-font-family);

    .error-icon {
      margin-right: var(--gap-msmall);
      margin-bottom: var(--gap-regular);
      transform: translate(0, 6px);
    }

    .error-message {
      text-align: center;
    }
  }
}
