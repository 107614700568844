.subscription-detail-page .portal-panel {
  width: 100%;

  .image-container {
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: var(--plan-group-card-size-sm);
    }
  }
}
