// Small tablets and large smartphones (landscape view)
$screen-xs: 375px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 992px;

// Large tablets and desktops
$screen-xl: 1200px;

:root {
  --screen-xs: 375px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
}
