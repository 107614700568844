@import "../styles/breakpoints";

.plan-card.skeleton {
  gap: var(--gap-lg);
  width: var(--card-width);
  border: 1px solid var(--plan-card-border-color);

  div:not(.content) {
    border-radius: var(--radius-lg);
    background: linear-gradient(
      to right,
      var(--grey-8) 8%,
      var(--grey-9) 18%,
      var(--grey-8) 33%
    );
    background-color: var(--grey-8);
    background-size: 1200px 100%;
    animation: shimmer 2200ms forwards infinite linear;
  }

  .image {
    height: var(--plan-card-image-height);
  }

  .content {
    .title-section {
      height: var(--font-size-3xl);
    }

    .price-section {
      height: var(--card-price-section-min);
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}
