sl-details {
  margin: 0 0 var(--gap-regular) 0;

  &:last-child {
    margin-bottom: 0;
  }

  &::part(base) {
    border: 2px solid var(--color-border-light);
  }

  &::part(header) {
    background-color: white;
    border-radius: 0;
    transition: filter var(--speed-fast);
    &:hover {
      filter: brightness(95%);
    }
  }
}
