.payment-method-form {

  sl-input.cvc {
      &::part(base) {
        max-width: 100px;
      }
      &::part(label) {
        width: 100%;
      }
  }

  .expiry-date {
    display: flex;

    sl-input {
      &::part(base) {
        max-width: 60px;
      }
      &::part(label) {
        width: 100%;
      }
      &::part(help-text) {
        height: 20px;
        color: rgb(199, 37, 5);
      }
    }

    .expiry-month {
      &::part(form-control) {
        width: 60px;
      }
    }

    .expiry-year {
      &::part(help-text) {
        transform: translate(-80px, 0);
      }
    }

    .divider {
      margin-top: 25px;
      font-size: var(--font-size-mlarge);
      font-weight: bolder;
      color: var(--color-font-faded);
    }
  }

  .form-footer {
    display: flex;
    justify-content: flex-end;
  }

  #sq-payment-form {
    width: 100%;

    .sq-label {
      font-weight: normal;
      color: var(--color-font-faded);
      font-size: var(--font-size-msmall);
      text-transform: capitalize;
    }

    .square-input-wrapper {
      display: flex;
      flex-direction: column;
      iframe {
        border: none;
        border-bottom: solid #BEC2D8 3px;
        height: var(--sl-input-height-medium);
        background-color: #F9FAFD;
      }

      &.error {
        iframe {
          border-bottom-color: red;
          background-color: #ff000011;
        }

        .error-text {
          font-size: var(--font-size-small);
          color: red;
        }
      }
    }
  }

  .payment-method-spinner-container {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.loading {
    #sq-payment-form {
      display: none;
    }
  }
}