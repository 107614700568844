.alert-stack {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--sl-z-index-toast);
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap-reverse;
  padding: var(--gap-large);
  pointer-events: none;

  &.merchant {
    left: var(--merchant-menu-width);
    width: calc(100% - var(--merchant-menu-width));
  }
}