@import '../../styles/breakpoints.scss';

.payment-card {
  width: 100%;
  min-height: var(--card-height-payment);
  background-color: white;
  border-radius: var(--border-radius-large);
  display: flex;
  z-index: 1;
  cursor: pointer;

  &.payment-card-mini {
    .payment-card-content {
      @media screen and (min-width: $screen-lg) {
        max-width: var(--card-width-subscription);
      }
    }
  }

  .payment-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 100%;
    width: 100%;
    padding: var(--gap-small) var(--gap-regular);

    .payment-card-top-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .payment-card-date {
        color: var(--color-font-dark);
        font-family: var(--default-font-family);
        font-weight: 500;
        font-size: var(--font-size-regular);
      }
    }

    .payment-card-bottom-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: $screen-xs) {
        flex-direction: row;
        align-items: center;
      }

      .payment-card-title {
        font-weight: 600;
        font-size: var(--font-size-mmlarge);
        font-family: var(--default-font-family);
        color: var(--color-font-dark);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: var(--gap-msmall);
        line-height: 1.2em;
      }

      .payment-card-price .price-text {
        font-size: var(--font-size-mmlarge);
      }
    }

  }
}
