@import '../../../styles/breakpoints.scss';

.quote-spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .quote-spinner {
    margin: var(--gap-large) 0;
  }
}

.confirm-price-section {
  display: flex;
  align-items: baseline;
  margin-bottom: var(--gap-regular);

  .price-icon {
    font-size: var(--font-size-mregular);
    margin-right: var(--gap-regular);
    transform: translate(0, 2px);
    min-width: 20px;
  }

  .confirm-price-sentence {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .price-text {
      font-size: var(--font-size-regular);
      font-weight: bold;
      margin: 0 var(--textual-space);
      display: flex;
      align-items: center;
    }
  }
}

.step-container-footer {
  margin-top: var(--gap-regular);
}

sl-alert.billing-warning {
  margin-bottom: var(--gap-regular);

  &::part(base) {
    border-color: var(--sl-color-primary-50);
  }
}
