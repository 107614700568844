.image-upload-container {
  display: flex;
  flex-direction: column;

  .image-upload-input {
    width: 100%;
    margin-right: var(--gap-regular);
    margin-bottom: var(--gap-regular);

    sl-input.url-input {
      width: 100%;
    }

    .image-upload-button {
      margin-top: var(--gap-regular);
    }
  }

  .image-upload-preview-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .image-upload-filename {
      z-index: 10;
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 40px;
      margin-right: var(--gap-regular);
      padding: var(--gap-regular) var(--gap-mlarge);
      border-radius: var(--border-radius-large);
      background-color: white;

      .filename-spinner {
        margin: 0 var(--gap-large) 0 0;
        font-size: var(--font-size-msmall);
      }

      sl-button {
        display: flex;

        &::part(base) {
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          padding: 0;
        }

        .x-icon {
          font-size: var(--font-size-mlarge);
        }
      }

      .warning-icon {
        margin-right: var(--gap-small);
        color: var(--sl-color-warning-400);
        font-size: 18px;
        transform: translate(0, 3px);
      }
    }

    .image-upload-preview {
      &.full-height {
        min-height: 15vw;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      .image-upload-preview-overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #FFF8;

        .image-upload-preview-spinner {
          margin: var(--gap-large) 0;
          font-size: 72px;
        }
      }
    }
  }
}
