.pricing-component-quantity-input-container {
  padding-right: var(--gap-small);
  width: 100%;

  sl-checkbox.quantity-input {
    &::part(base) {
      padding: 0 var(--gap-small)
    }
  }

  .quantity-input {
    width: 100%;

    &::part(base) {
      height: var(--gap-xlarge);
      min-height: 0;
      min-width: 0;
      display: flex;
      align-items: center;
    }

    &::part(input) {
      width: 5em;
    }

    &.different {
      &::part(base) {
        background-color: #00ff0011;
      }
    }

    &::part(form-control) {
    }

    &::part(form-control-label),
    .quantity-label {
      font-weight: 500;
      font-size: 100%;
      font-family: var(--default-font-family);
      height: fit-content;
    }

    &::part(form-control-input) {
    }
  }

  .quantity-fixed {
    height: var(--gap-xlarge);
    min-height: 0;
    min-width: 0;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    font-family: var(--default-font-family);
  }

  .quantity-unit {
    max-width: 10em;
    font-size: var(--font-size-small);
    color: var(--color-font-faded);
  }

  .minus, .plus {
    &::part(base) {
      width: var(--gap-large);
      height: var(--gap-large);
      min-width: 0;
      min-height: 0;
      border-radius: var(--gap-large);
      padding: 0;
      text-align: center;
      margin: 0 var(--gap-small);
    }

    &::part(prefix), &::part(suffix) {
      display: none;
    }

    &::part(label) {
      font-size: var(--font-size-mlarge);
      transform: translate(2px, 0);
    }
  }

  sl-checkbox {
    &::part(base) {
      border: var(--input-box-border);
    }
  }
}
