@import '../../../styles/breakpoints';

.portal-home-title {
  align-self: flex-start;
  
  span {
    color: var(--action-primary);
  }
}

.home-grid {
  display: grid;
  grid-template-areas:
    "subscriptions-area"
    "billing-history-area"
    "account-area";
  grid-auto-rows: min-content;
  gap: var(--gap-4xl);
  max-width: 100vw;

  @media screen and (min-width: $screen-lg) {
    grid-template-areas:
    "subscriptions-area subscriptions-area"
    "billing-history-area account-area";
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  
    .subscriptions-area { grid-area: subscriptions-area; }
    .billing-history-area { grid-area: billing-history-area; }
    .account-area { grid-area: account-area; }
  
    .portal-panel {
      height: 100%;
    }
  }

  .home-subscriptions-list {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);

    @media screen and (min-width: $screen-lg) {
      display: unset;
    }

    .home-subscriptions-list-empty {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: var(--gap-regular) 0 var(--gap-xxlarge) 0;
    }
  }

  .home-billing-history-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--color-font-dark);
    font-weight: normal;

    .total-payments {
      font-size: var(--font-size-mregular);
    }

    .latest-payment {
      font-weight: 500;
      font-size: var(--font-size-regular);
    }
  }
}
