@import "../../styles/breakpoints";

.controls-card {
  display: flex;
  width: 100%;
  margin: var(--gap-large) 0;
  background-color: white;

  .controls-card-content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-large);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: var(--gap-small) var(--gap-mlarge);

    @media screen and (min-width: $screen-xs) {
      flex-direction: row;
    }

    .controls-card-details-section {
      display: flex;
      flex-direction: column;
      gap: var(--gap-mlarge);

      @media screen and (min-width: $screen-sm) {
        margin-bottom: 0;
      }

      @media screen and (min-width: $screen-md) {
        flex-direction: row;
      }

      .controls-card-detail {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        color: var(--color-font-dark);

        @media screen and (min-width: $screen-sm) {
          align-items: flex-start;
        }

        @media screen and (min-width: $screen-md) {
          margin-bottom: 0;
        }

        .controls-card-detail-label {
          font-size: var(--font-size-regular);
        }

        .controls-card-detail-value,
        .price-text {
          margin: 0;
          font-size: var(--font-size-mlarge);
        }
      }
    }

    .controls-card-controls-section {
      display: flex;
    }
  }
}
