.complete-environments-section {
  .environments-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: var(--gap-large);
    padding-left: 60px;

    .environments-row {
      display: flex;
      margin-bottom: var(--gap-regular);

      .environment-name {
        min-width: 140px;
        text-align: left;

        .icon {
          margin-right: var(--gap-regular);
          transform: translate(0, 3px);
        }
      }

      .environment-url {
        font-weight: bold;
        text-align: left;
      }
    }
  }
}

.complete-catchphrase-container {
  width: 100%;
  margin: var(--gap-large) 0 !important;
  text-align: center;

  .complete-catchphrase {
    color: var(--color-font-light);
    font-weight: bold;
    font-size: var(--font-size-regular);
    font-family: var(--default-font-family);
  }
}
