@import '../../styles/breakpoints';

.subscription-detail-manage-section {
  .subscription-detail-manage-top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--gap-large);

    sl-button {
      width: 100%;
    }

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      sl-button {
        width: unset;
      }
    }

    .subscription-detail-price-summary {
      display: flex;

      .price-arrow {
        display: flex;
        align-items: center;
        margin: 0 var(--gap-large);

        sl-icon {
          font-size: var(--font-size-mmlarge);
        }
      }

      .subscription-detail-current-price, .subscription-detail-new-price {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--gap-regular);

        .price-label {
          color: var(--color-font-dark);
          font-family: var(--default-font-family);
          font-weight: 500;
          line-height: 1.5em;
          font-size: var(--font-size-regular);
        }

        .price-text-and-spinner {
          display: flex;
          align-items: flex-end;

          .price-text {
            justify-content: flex-start;
            font-weight: 500;
          }

          .price-spinner {
            margin-left: var(--gap-regular);
            font-size: var(--font-size-mmlarge);
          }
        }

        .period-text {
          min-width: 80px;
          font-size: var(--font-size-msmall);
          color: var(--color-font-faded);
        }
      }

      .subscription-detail-new-price.different {
        .price-text {
          color: var(--sl-color-success-40);
        }
      }
    }
  }

  .subscription-detail-pricing-components-grid {
    display: grid;
    border: 2px solid var(--color-border-light);
    border-radius: var(--border-radius-large);
    grid-template-columns: auto auto auto auto;
    grid-auto-flow: dense;

    @media screen and (min-width: $screen-md) {
      .component-name {
        align-items: center;
      }
    }

    .component-name {
      grid-column: span 2;
      margin-top: var(--gap-regular);
      border-bottom: none;
    }

    .pricing-component-quantity-input-container {
      border-bottom: none;

      .quantity-unit {
        text-align: right;
      }

      .quantity-fixed {
        justify-content: flex-end;
      }
    }

    .component-price-per-unit {
      grid-column: span 2;
    }

    .no-components-message {
      grid-column: span 4;
      text-align: center;
    }

    & > div {
      border-bottom: 2px solid var(--color-border-light);
      padding-bottom: var(--gap-small);
      padding-right: var(--gap-regular);

      &.component-name {
        padding-left: var(--gap-regular);
        padding-right: var(--gap-large);
      }

      &:nth-last-child(-n+4) { // All elements in last row
        border-bottom: none;
      }
    }

    .component-name {
      font-family: var(--default-font-family);
      font-weight: 500;
      font-size: var(--font-size-mregular);
      display: flex;
      line-height: 1em;
    }

    .pricing-component-quantity-input-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: var(--gap-regular);
    }

    .component-price-per-unit, .component-price-total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      //justify-content: flex-end;
      padding-left: var(--gap-mlarge);

      .price-text {
        font-size: var(--font-size-mlarge);
        font-weight: 500;
        height: var(--font-size-mlarge);
      }

      .component-unit {
        min-width: 80px;
        font-size: var(--font-size-small);
        text-align: right;
        color: var(--color-font-faded);
      }
    }
  }

  .subscription-management-controls {
    padding-block: var(--gap-xl);

    section {
      display: flex;
      gap: var(--gap-xl);

      button {
        width: 100%;
        border: 1px solid;
        border-radius: var(--radius-lg);
        background-color: var(--white);


                @media screen and (min-width: $screen-lg) {
                  width: unset;
                }

        &:hover {
          background-color: #00000005;
        }

        &:active {
          background-color: #0001;
        }

        &.pause {
          border-color: orange;
          color: orange;
        }

        &.cancel {
          border-color: red;
          color: red;
        }
      }
    }
  }
}
