@import '../../../styles/breakpoints';

.payment-detail-page .portal-panel {
  width: 100%;
  max-width: 800px;

  .payment-detail-overview-section {
    display: flex;

    .labelled-container {
      width: 100%;
    }

    .payment-detail-panel > .labelled-container-card {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-gap: var(--gap-large) 0;

      @media screen and (min-width: $screen-md) {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 var(--gap-large);
      }

      .payment-detail-sub-panel {
        color: var(--color-font-dark);
        font-weight: bold;
        font-size: var(--font-size-mlarge);

        &.payment-detail-date > .labelled-container-card {
          padding: var(--gap-small) var(--gap-regular);
        }
      }
    }
  }
}
