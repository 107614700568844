.preview-plan-card-panel-container {
  display: flex;
  flex-direction: column;

  .preview-plan-card-panel-title {
    margin: var(--gap-regular) 0;
    font-size: var(--font-size-mlarge);
  }

  .preview-plan-card-panel {
    display: flex;

    .plan-card {
      margin: 0;

      .details-section {
        overflow: hidden;
      }
    }
  }
}
