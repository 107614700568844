.subscription-link {
  display: flex;
  gap: var(--gap-lg);
  align-items: center;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: var(--color-font-dark);

  .img {
    display: inline-block;
    object-fit: cover;
    height: 100%;
    border-radius: var(--radius-md);
    aspect-ratio: 1;
  }

  .icon {
    margin-left: auto;
  }
}
