@import "../styles/breakpoints";

.plans-sortable-row {
  .controls {
    .controls-container {
      margin-right: var(--gap-sm);
      transition: all 100ms;

      @media screen and (min-width: $screen-lg) {
        opacity: 0;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 24px;
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: var(--radius-md);
        background-color: var(--white);
        box-shadow: 0 0 2px #0004;
        font-weight: 500;
        font-size: var(--font-size-xs);
        letter-spacing: unset;

        &:hover {
          box-shadow: 0 0 2px #000;
        }
      }
    }
  }

  &:hover {
    background-color: var(--grey-9);

    .controls {
      .controls-container {
        opacity: 1;
      }
    }
  }

  .drag-icon {
    cursor: grab;
  }
}
