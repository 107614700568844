@import "../../styles/breakpoints";

.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  overflow: auto;
  width: 95vw;
  height: 100vh;
  padding: var(--gap-xl);
  background-color: white;
  transition: all 400ms;
  transform: translateX(100%);

  @media screen and (min-width: $screen-lg) {
    width: 80vw;
    padding: var(--gap-2xl);
  }

  &.open {
    transform: translateX(0);
  }

  .close {
    position: absolute;
    top: var(--gap-xl);
    right: var(--gap-xl);
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    border: 0;
    color: black;
    font-size: var(--font-size-2xl);
  }

  .danger-zone {
    margin-top: var(--gap-2xl);

    h3 {
      font-size: var(--font-size-xl);
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: var(--gap-xl) 0;
      border: 1px solid var(--error);
      border-radius: var(--radius-md);
      color: var(--error);
      font-size: var(--font-size-sm);
      letter-spacing: normal;
    }
  }
}
