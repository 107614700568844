@import "../styles/breakpoints";

.coupon-panel-container {
  width: 100%;

  .coupon-panel {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-lg);
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: var(--gap-xl);
    border-radius: var(--radius-md);
    background-color: var(--white);

    @media screen and (min-width: $screen-sm) {
      gap: var(--gap-xl);
    }

    @media screen and (min-width: $screen-md) {
      gap: var(--gap-2xl);
    }

    @media screen and (min-width: $screen-lg) {
      gap: var(--gap-3xl);
    }

    .coupon-text-container {
      color: var(--color-font-regular);
      font-weight: normal;
      font-size: var(--font-size-lg);
      text-align: center;
    }

    .coupon-input-container {
      width: 100%;
      max-width: var(--card-width-main);

      .coupon-input {
        &::part(input) {
          padding: 0 var(--gap-md);
        }
      }
    }

    .coupon-apply-button-container {
      .coupon-apply-button {
        &::part(base) {
          width: 200px;
        }
      }
    }
  }

  .coupon-icon {
    padding-left: var(--gap-sm);
    font-size: var(--font-size-md);
    transform: translate(0, 1px);
  }
}
