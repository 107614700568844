@import './styles/breakpoints';

.merchant-container {
  width: 100%;
  height: 100%;

  .merchant-page-container {
    position: relative;
    left: 0;
    min-height: 100%;

    @media screen and (min-width: $screen-lg) {
      margin-left: var(--merchant-menu-width);
    }
  }

  ul {
    list-style: none;
  }
}
