.payment-method-card {
  z-index: 1;
  display: flex;
  width: 100%;
  min-height: var(--card-height-payment-method);
  padding: 0;
  border: 0;
  background-color: white;

  .payment-method-card-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    .payment-method-card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      margin-right: var(--gap-regular);

      sl-icon::part(base), img {
        max-width: 60px;
        max-height: 40px;
      }

      sl-icon {
        width: 24px;
        height: 24px;
      }
    }

    .payment-method-card-number {
      margin-right: var(--gap-regular);
    }
  }

  &.hide-card {
    background-color: transparent;
    box-shadow: none;

    > .payment-method-card-content {
      justify-content: flex-start;
      padding: 0;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.set-width {
    width: 360px;
  }
}
