sl-select {
  font-family: var(--default-font-family);

  &::part(label) {
    font-weight: normal;
    color: var(--color-font-faded);
    font-size: var(--font-size-msmall);
  }
  &::part(base) {
    background-color: white;
    border-radius: var(--border-radius-regular);
  }

  sl-menu-item {
    &::part(base) {
      font-size: var(--font-size-msmall);
    }

    &::part(prefix) {
      min-width: var(--gap-small);
    }
  }
}
