#flexcheck-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 60px;
  max-height: 100px;
  padding: 4px 12px;
  border: none;
  border-radius: var(--border-radius-regular);
  background: linear-gradient(to right, #F92C8B, #B02CD6);
  color: white;
  font-weight: bold;
  font-size: var(--font-size-regular);
  font-family: var(--default-font-family);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
