@import '../../../styles/breakpoints';

.plan-group {
  gap: var(--gap-2xl);
  margin-bottom: var(--gap-2xl);
  padding: var(--gap-2xl);
  border-radius: var(--radius-md);
  background-color: var(--white);


  .plan-group-img {
    object-fit: var(--plan-group-hero-image-fit);
    width: var(--plan-group-hero-image-width);
    max-height: var(--plan-group-hero-image-max-height);
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xl);
    align-self: flex-start;
    padding: 0;

    h2 {
      align-self: start;
      color: var(--color-font-dark);
    }
  }
}
