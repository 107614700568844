@import '../styles/breakpoints';

.terms-and-conditions-dialog {
  iframe {
    height: 70vh;
    width: 100%;
  }

  &::part(title) {
    margin-left: var(--gap-regular);
    font-size: var(--font-size-large);
  }

  &::part(body) {
    padding: var(--gap-xxlarge);
    width: 100%;
  }

  &::part(panel) {
    width: 90vw;
    font-weight: normal;

    @media screen and (min-width: $screen-lg) {
      width: inherit;
      min-width: 800px;
      max-width: 90vw;
    }

    p, strong {
      font-size: var(--font-size-msmall);
    }

    h4 {
      font-weight: bold;
      font-size: var(--font-size-mlarge);
      text-align: center;
      text-decoration: underline;
    }

    h5 {
      font-weight: bold;
      font-size: var(--font-size-regular);
    }

    h6 {
      font-weight: bold;
      font-size: var(--font-size-msmall);
    }

    ol {
      counter-reset: item;

      li {
        position: relative;
        display: block;
      }

      li::before {
        content: counters(item, ".") ".";
        position: absolute;
        right: 10px; /* space between number and text */
        margin-right: 100%;
        counter-increment: item;
      }

      &.letters {
        counter-reset: list;

        li {
          list-style: none;
        }

        li::before {
          content: "(" counter(list, lower-alpha) ") ";
          counter-increment: list;
        }
      }

      &.numerals {
        counter-reset: list;

        li {
          list-style: none;
        }

        li::before {
          content: "(" counter(list, lower-roman) ") ";
          counter-increment: list;
        }
      }
    }
  }
}
