.loading-button-container {
  sl-button {
    &::part(base) {
      font-size: 24px;
    }
  }

  .error-message {
    margin-right: var(--gap-xxlarge);
    color: var(--color-font-error);
    font-weight: normal;
    font-size: var(--font-size-msmall);
  }

  .error-icon {
    min-width: 16px;
    margin-right: var(--gap-regular);
    color: var(--color-font-error);
  }
}
