sl-spinner {
  --color:  var(--color-button-submit);
  font-size: var(--font-size-large);
  &::part(base) {
    --indicator-color: var(--color-button-submit);
  }

  &.custom {
    --color: var(--color-button-submit-custom);
    &::part(base) {
      --indicator-color: var(--color-button-submit-custom);
    }
  }
}
