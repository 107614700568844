.preview-checkout-panel-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-xxlarge);

  .preview-checkout-panel-title-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--gap-regular);

    sl-button.preview-reload-button {
      &::part(label) {
        font-size: var(--font-size-mregular);
        transform: translate(0, 1px);
      }
    }

    .preview-checkout-panel-title {
      font-size: var(--font-size-mlarge);
    }
  }

  .user-background {
    transform: scale(0.1);
  }

  iframe {
    border-radius: var(--border-radius-large);
    pointer-events: none;
  }
}
