@import '../styles/breakpoints';

.currency-select {
  .currency-select-button, .currency-select-menu-item {
    font-family: var(--default-font-family);

    &::part(label) {
      color: var(--color-font-dark);
      font-weight: normal;
      
    }

    &::part(prefix) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: var(--gap-large);
      padding-left: var(--gap-regular);
      color: var(--color-font-dark);
      font-size: var(--font-size-regular);
    }
  }

  .currency-select-button::part(label) {
    transform: translate(0, 1px);
  }
}
