.square-button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: var(--gap-regular);
  &:last-child {
    margin-right: 0;
  }

  sl-button {
    sl-icon {
      transform: scale(3);
      filter: brightness(0) saturate(100%) invert(100%);
    }

    &::part(base) {
      min-height: 44px;
      max-height: 44px;
      max-width: 44px;
      min-width: 44px;
      padding: 0;
    }

    &::part(label) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
    }
  }

  sl-spinner {
    --color: white;
    &::part(base) {
      --indicator-color: white;
    }
  }

  &.smaller-icon {
    sl-icon {
      transform: scale(2);
    }
  }

  &.cancel {
    sl-button::part(base)  {
      background-color: var(--color-button-cancel);
      box-shadow: 0 2px 20px -10px var(--color-button-cancel-shadow);
    }

    .square-button-label {
      color: var(--color-button-cancel);
    }
  }

  &.pause {
    sl-button::part(base)  {
      background-color: var(--color-button-pause);
      box-shadow: 0 2px 20px -10px var(--color-button-pause-shadow);
    }

    .square-button-label {
      color: var(--color-button-pause);
    }
  }

  &.primary {
    sl-button::part(base) {
      box-shadow: 0 2px 20px -10px var(--color-button-shadow-primary);
    }
    .square-button-label {
      color: var(--color-button-gradient-primary-2)
    }

    &.custom {
      sl-button::part(base) {
        box-shadow: 0 2px 20px -10px var(--color-button-shadow-primary-custom);
      }

      .square-button-label {
        color: var(--color-button-gradient-primary-2-custom)
      }
    }
  }

  &.secondary {
    sl-button::part(base) {
      box-shadow: 0 2px 20px -10px var(--color-button-shadow-secondary);
    }
    .square-button-label {
      color: var(--color-button-gradient-secondary-2)
    }

    &.custom {
      sl-button::part(base) {
        box-shadow: 0 2px 20px -10px var(--color-button-shadow-secondary-custom);
      }

      .square-button-label {
        color: var(--color-button-gradient-secondary-2-custom)
      }
    }
  }

  .square-button-label {
    text-transform: uppercase;
    font-weight: bold;
    font-family: var(--default-font-family);
    line-height: 1em;
    margin-top: var(--gap-small);
    user-select: none;

    &.disabled {
      opacity: 50%;
    }
  }
}
