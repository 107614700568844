.plans-list.plans-add-list {
  .plans-table {
    position: relative;

    .plans-table-header-row {
      th {
        position: sticky;
        top: 0;
        z-index: 98;

        &:first-child {
          width: 32px;
        }

        &:not(:first-child) {
          min-width: 120px;
        }
      }
    }

    .plans-table-add-row {
      td {
        vertical-align: middle;
        height: var(--merchant-table-row-height);
        padding-left: 10px;
        border: 0;
        user-select: none;
      }

      &:hover {
        td {
          filter: brightness(95%);
        }
      }

      &.added {
        pointer-events: none;
      }
    }

    .product-row-container {
      border-radius: 0;

      .product-row {
        cursor: pointer;

        td {
          background-color: var(--color-merchant-table-row-1);
        }

        .dropdown-arrow {
          .dropdown-arrow-icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: var(--font-size-small);

            sl-icon {
              transition: transform var(--speed-mfast);
            }
          }
        }
      }

      .plan-row {
        cursor: pointer;

        td {
          background-color: var(--color-merchant-table-row-1);
        }

        &.added {
          cursor: default;

          .enabled {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: var(--font-size-regular);
          }

          td {
            color: var(--color-font-faded);
          }
        }

        .enabled .merchant-checkbox {
          visibility: hidden;

          &.active {
            background-color: white;
            visibility: visible;
            cursor: default;

            .merchant-checkbox-icon {
              // Set svg icon to #6B1FCC (generated from https://codepen.io/sosuke/pen/Pjoqqp)
              filter: brightness(0) saturate(100%) invert(13%) sepia(93%)
                saturate(4378%) hue-rotate(266deg) brightness(90%) contrast(97%);
            }
          }
        }
      }

      &.open {
        .product-row .dropdown-arrow-icon-container {
          sl-icon {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
