@import "../styles/colors";
@import "../styles/breakpoints";
@import "../styles/sizes";

.flexcheck-header {
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--gap-3xl);

  @media screen and (min-width: $screen-md) {
    flex-direction: row;
  }

  #flexcheck-header-logo {
    max-width: var(--header-width-maximum);
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-2xl);
    justify-content: center;
    align-items: center;

    .cart-container {
      .cart-button {
        &::part(base) {
          // TODO: create sizing system and don't use gap variable for size
          // ! Removing these INCREASES the size because min-width from Button.scss is then used
          width: var(--button-sm);
          min-width: unset;
          height: var(--button-sm);
        }
  
        &::part(label) {
          font-size: var(--font-size-lg);
        }
  
        sl-badge {
          &::part(base) {
            font-size: var(--font-size-xs);
          }
        }
      }
    }
  }

}
