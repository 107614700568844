/* stylelint-disable custom-property-empty-line-before */
// TODO: remove msmall mlarge mmlarge etc and standardise sizings
// to xs, sm, md, lg, xl, {num}xl
:root {
  --textual-space: 0.25em; // From Wikipedia on general-purpose space: "Typical values range from 1/5 em to 1/3 em"

  --gap-xsmall: 3px;
  --gap-small: 6px;
  --gap-msmall: 8px;
  --gap-regular: 12px;
  --gap-mlarge: 16px;
  --gap-large: 24px;
  --gap-xlarge: 36px;
  --gap-xxlarge: 48px;

  --gap-xs: 3px;
  --gap-sm: 6px;
  --gap-md: 8px;
  --gap-lg: 12px;
  --gap-xl: 16px;
  --gap-2xl: 24px;
  --gap-3xl: 36px;
  --gap-4xl: 48px;

  --font-size-small: 12px;
  --font-size-msmall: 14px;
  --font-size-regular: 16px;
  --font-size-mregular: 20px;
  --font-size-mlarge: 24px;
  --font-size-mmlarge: 30px;
  --font-size-large: 36px;
  --font-size-xlarge: 48px;

  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 20px;
  --font-size-xl: 24px;
  --font-size-2xl: 30px;
  --font-size-3xl: 36px;
  --font-size-4xl: 48px;

  --button-sm: 40px;

  // We should not need to add this to everything
  --default-font-family: 'Montserrat', sans-serif;
  --input-box-border: 1px solid #909090;

  --body-minimum-width: 260px;
  --body-minimum-width-seventyfive: calc(var(--body-minimum-width) * 0.75);
  --max-card-width: 360px;
  --card-width: min(var(--max-card-width), 100vw);
  --card-width-main-min: calc(max(100vw, var(--body-minimum-width))); // If body is less than --body-minimum-width then make the card more than 100vw
  --card-width-main: calc(min(var(--max-card-width), var(--card-width-main-min))); // If card would be more than 360px then limit it
  --header-width-maximum: calc(max(75vw, var(--body-minimum-width-seventyfive)));
  --card-height-main: 640px;
  --card-details-height: 200px;

  --card-width-mini: 768px;
  --card-height-mini: 100px;
  --card-price-section-min: 120px;

  --radius-none: 0;
  --radius-sm: 2px;
  --radius-md: 8px;
  --radius-lg: 12px;
  --radius-xl: 18px;
  --radius-max: 999px;

  // TODO: replace instances of below with above
  --border-radius-large: var(--radius-none);
  --border-radius-alert: var(--radius-none);
  --border-radius-dropdown: var(--radius-sm);
  --border-radius-input: var(--radius-md);
  --border-radius-plan-card: var(--radius-lg);
  --border-radius-dialog: var(--radius-lg);
  --border-radius-buttons: var(--radius-max);
  --border-radius-regular: var(--radius-max);
  --border-radius-small: var(--radius-max);
  --border-radius-xlarge: var(--radius-max);

  --border-width-regular: 2px;

  --merchant-menu-width: 250px;
  --merchant-top-bar-height: 50px;
  --merchant-menu-padding: 20px;
  --merchant-table-row-height: 45px;

  --header-height: 64px;
  --portal-top-bar-height: 64px;
  --header-top-margin-md: 40px;
  --header-bottom-padding-md: 25px;

  --card-width-subscription: 350px;
  --card-height-subscription: 310px;
  --card-height-payment: 80px;
  --card-height-payment-method: 48px;

  --option-dropdown-height: 50px;
  --customize-pricing-dropdown-height: 50px;

  --plan-group-card-size-sm: 200px;
  --plan-group-card-size-md: 250px;
  --plan-group-card-size-lg: var(--card-width);

  // Commonly customised
  --button-radius: var(--radius-max);
  --plan-card-radius: var(--radius-lg);
  --plan-card-border-width: 2px;
  --plan-card-border: var(--plan-card-border-width) solid var(--plan-card-border-color);
  --plan-card-top-border-width: 12px;
  --plan-card-title-alignment: center;
  --plan-card-title-weight: 800;
  --plan-card-title-size: var(--font-size-mlarge);
  --plan-card-description-weight: 400;
  --plan-card-description-size: var(--font-size-regular);
  --subscription-title-weight: 800;
  --subscription-title-size: var(--font-size-mregular);
  --plan-card-image-height: 250px;
  --plan-card-image-fit: cover;
  --plan-group-hero-image-fit: cover;
  --plan-group-hero-image-max-height: 20vh;
  --plan-group-hero-image-width: 100%;
}
