sl-tab-group {
  &::part(tabs) {
    font-size: var(--font-size-large);
  }

  &::part(nav) {
    &:focus {
      outline: none;
    }
  }
}

sl-tab {
  &::part(base) {
    font-size: var(--font-size-msmall);
    font-family: var(--default-font-family);
    padding: var(--gap-regular);
  }
}

sl-tab-panel {
  &::part(base) {
    padding: var(--gap-regular) 0;
  }
}
