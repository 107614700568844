@import "../styles/breakpoints";

.plan-card-mini {
  width: 100%;
  max-width: none;
  height: auto;
  min-height: var(--card-height-mini);
  margin: var(--gap-regular) 0 var(--gap-large) 0;

  @media screen and (min-width: $screen-sm) {
    max-width: var(--card-width-mini);
  }

  &.no-details {
    max-height: var(--card-height-mini);
  }

  &.gradient-border {
    background-image: linear-gradient(white, white);
    background-clip: content-box, border-box;
    background-origin: border-box;
  }

  .plan-card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .plan-card-mini-header {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      width: 100%;
      height: var(--card-height-mini);
      max-height: var(--card-height-mini);
      padding: var(--gap-regular) 0;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        text-align: left;
      }

      &.multiple-plans {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        text-align: left;
      }

      .title-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        text-align: left;
        word-wrap: none;

        .title {
          overflow: hidden;
          margin: var(--gap-small) 0;
          font-size: var(--font-size-mlarge);
          text-overflow: ellipsis;
        }

        .coupon {
          margin: 5px 0 0;
          color: var(--color-font-faded);
          font-size: var(--font-size-msmall);
          text-transform: uppercase;

          sl-icon {
            margin-right: var(--gap-small);
            transform: translate(0, 2px);
          }
        }

        .trial {
          margin: 0;
          color: var(--color-font-regular);
          font-size: var(--font-size-msmall);
        }
      }

      .price-section {
        .price-listing {
          display: grid;
          grid-template-columns: auto auto;
          align-items: baseline;
          height: 100%;
          margin: 0;
          text-align: left;

          .tax-section {

            font-size: var(--font-size-small);

            .price-text {
              font-size: var(--font-size-small);
              margin: var(--gap-xsmall);
              font-weight: normal;
            }
          }

          @media screen and (min-width: $screen-sm) {
            flex-direction: column;
            align-items: flex-end;
            text-align: right;
            grid-template-columns: auto;
          }

          .price-text {
            align-items: center;
            height: var(--font-size-mlarge);
            margin: 0 var(--gap-msmall) 0 0;
            font-size: var(--font-size-regular);

            @media screen and (min-width: $screen-sm) {
              margin: var(--gap-xsmall) 0 -4px 0;
            }
          }

          .period {
            color: var(--color-font-regular);
            font-weight: normal;
            font-size: var(--font-size-msmall);
            line-height: 1.2em;
            grid-column: span 2;
            @media screen and (min-width: $screen-sm) {
              grid-column: initial;
            }
          }
        }
      }
    }

    .plan-card-mini-details {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      margin-top: var(--gap-regular);

      .column {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
